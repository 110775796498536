import React, { createContext, useContext, useMemo } from 'react';
import { RATE_PASSENGER, RATE_TRIP, SKIP_RATING } from 'src/graphql/rating/mutations';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';

const RatingContext = createContext();

const useRating = () => useContext(RatingContext);

function RatingProvider({ children }) {
  const [rateTrip, rateTripState] = useSafeMutation(RATE_TRIP, {
    displaySnackbar: false,
    refetchQueries: ['getTripById', 'getMyPendingRatings'],
  });

  const [ratePassenger, ratePassengerState] = useSafeMutation(RATE_PASSENGER, {
    displaySnackbar: false,
    refetchQueries: ['getTripById', 'getMyPendingRatings'],
  });

  const [skipRating, skipRatingState] = useSafeMutation(SKIP_RATING, {
    displaySnackbar: false,
    refetchQueries: ['getTripById', 'getMyPendingRatings'],
  });

  const memoizedContextValue = useMemo(
    () => ({
      ratePassenger,
      ratePassengerState,
      rateTrip,
      rateTripState,
      skipRating,
      skipRatingState,
    }),
    [ratePassenger, ratePassengerState, rateTrip, rateTripState, skipRating, skipRatingState]
  );

  return <RatingContext.Provider value={memoizedContextValue}>{children}</RatingContext.Provider>;
}

export { RatingProvider, useRating };
