const MESSAGE_TYPES = {
  CHECK_CAMERA_PERMISSIONS: 'CHECK_CAMERA_PERMISSIONS',
  COPY: 'COPY',
  DEBUG: 'DEBUG',
  GET_CONTACTS: 'GET_CONTACTS',
  GET_CONTACTS_PERMISSIONS: 'GET_CONTACTS_PERMISSIONS',
  GET_CURRENT_POSITION: 'GET_CURRENT_POSITION',
  GET_DEVICE_ID: 'GET_DEVICE_ID',
  GET_PERMISSIONS: 'GET_PERMISSIONS',
  HIDE_CAMERA: 'HIDE_CAMERA',
  OPEN_SETTINGS: 'OPEN_SETTINGS',
  OPEN_URL: 'OPEN_URL',
  REGISTER_FOR_PUSH_NOTIFICATIONS: 'REGISTER_FOR_PUSH_NOTIFICATIONS',
  REQUEST_CAMERA_PERMISSIONS: 'REQUEST_CAMERA_PERMISSIONS',
  REQUEST_CONTACTS_PERMISSIONS: 'REQUEST_CONTACTS_PERMISSIONS',
  REQUEST_POSITION_PERMISSIONS: 'REQUEST_POSITION_PERMISSIONS',
  SEND_SMS: 'SEND_SMS',
  SHARE: 'SHARE',
  SHOW_CAMERA: 'SHOW_CAMERA',
  STORE_TOKEN: 'STORE_TOKEN',
  TAKE_PHOTO: 'TAKE_PHOTO',
  WATCH_POSITION: 'WATCH_POSITION',
};

const EVENT_TYPES = {
  DONE: 'DONE',
  ERROR: 'ERROR',
  START: 'START',
};

export { EVENT_TYPES };
export default MESSAGE_TYPES;
