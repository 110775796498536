import { Button } from '@mui/material';
import React from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import kycNeededAnimationData from 'src/assets/animations/kyc_needed.json';
import MobileSheet from 'src/components/MobileSheet';
import PageWrapper from 'src/components/PageWrapper';
import useAuth from 'src/contexts/auth/useAuth';
import { PATH_USER } from 'src/routes/paths';
import AnimationWrapper from 'src/sections/onboardings/pre-signup-onboarding/AnimationWrapper';

function NeedsKYCPopupSheet() {
  const { user } = useAuth();

  const { mangoPayKycInformations } = user;
  const { needKycVerificationRelatedToTripsLength } = mangoPayKycInformations;

  const location = useLocation();

  const isKYCPage = matchPath(PATH_USER.me.verify, location.pathname);

  return (
    <MobileSheet
      height="auto"
      notSwipeable
      open={needKycVerificationRelatedToTripsLength && !isKYCPage}
      variant="card"
    >
      <PageWrapper
        subtitle="On a besoin d'informations supplémentaires avant de te laisser poursuivre"
        title="Vérifie ton profile"
        withoutLogo
      />
      <AnimationWrapper
        animationData={kycNeededAnimationData}
        animationProps={{ loop: false }}
        withBackground
      />
      <Button LinkComponent={RouterLink} to={PATH_USER.me.verify} variant="outlined">
        Vérifier maintenant
      </Button>
    </MobileSheet>
  );
}

export default NeedsKYCPopupSheet;
