import { gql } from '@apollo/client';

const ratingFragment = gql`
  fragment Rating on Rating {
    id
    tripId
    passengerId
    conductorId
    passengerRate
    conductorRate
    conductorComment
    passengerComment
    hasConductorSkipped
    hasPassengerSkipped
    createdAt
  }
`;

export default ratingFragment;
