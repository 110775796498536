import { ArrowForwardOutlined } from '@mui/icons-material';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { PATH_AUTH } from 'src/routes/paths';
import isDevelopmentMode from 'src/utils/isDevelopmentMode';

import Onboarding from '../onboarding';
import EarnMoneyStepContent from './steps/EarnMoneyStepContent';
import SignUpStepContent from './steps/SignUpStepContent';
import WelcomeStepContent from './steps/WelcomeStepContent';

function PreSignupOnboarding() {
  const [searchParams] = useSearchParams();
  const useApp = Boolean(window.ReactNativeWebView);

  const navigate = useNavigate();

  const steps = useMemo(
    () => [
      {
        children: WelcomeStepContent,
        subtitle: 'Voyager ensemble, plus facilement',
        title: 'Bienvenue sur Coccinelle!',
      },
      {
        children: EarnMoneyStepContent,
        subtitle: 'Voyage tout en accumulant des avantages',
        title: "Covoiture et gagne de l'argent",
      },
      {
        children: SignUpStepContent,
        subtitle: 'Commence dès maintenant',
        title: 'Rejoins-nous!',
      },
    ],
    []
  );

  const handleGoToSignUpPage = useCallback(() => {
    setTimeout(() => {
      const sponsorId = searchParams.get('sponsorId');
      const redirectionRegisterPath = sponsorId
        ? `${PATH_AUTH.register}?sponsorId=${sponsorId}`
        : PATH_AUTH.register;
      navigate(redirectionRegisterPath);
    }, 0);
  }, [navigate, searchParams]);

  if (!useApp && !isDevelopmentMode()) return null;

  return (
    <Onboarding
      disableClickIndicator
      endActionIcon={<ArrowForwardOutlined />}
      endActionLabel="Je m'inscris"
      fullHeight
      name="pre-signup-v1"
      notSkipable
      onAfterEnd={handleGoToSignUpPage}
      steps={steps}
    />
  );
}

export default PreSignupOnboarding;
