import {
  FingerprintOutlined,
  GradeOutlined,
  GroupOutlined,
  HandshakeOutlined,
  HomeOutlined,
  MovingOutlined,
} from '@mui/icons-material';
import { PATH_ADMIN } from 'src/routes/paths';

import USER_ROLES from '../constants/userRoles';

const icons = {
  FingerprintOutlined,
  GradeOutlined,
  GroupOutlined,
  HandshakeOutlined,
  HomeOutlined,
  MovingOutlined,
};

const administration = {
  children: [
    {
      chip: {
        color: 'warning',
        label: 32,
        variant: 'light',
      },
      icon: icons.FingerprintOutlined,
      id: 'surveillances',
      title: "Vérification d'identité",
      type: 'item',
      url: PATH_ADMIN.home,
    },
    {
      icon: icons.GroupOutlined,
      id: 'admin-users',
      title: 'Utilisateurs',
      type: 'item',
      url: PATH_ADMIN.users.list,
    },
    {
      icon: icons.HandshakeOutlined,
      id: 'admin-partners',
      title: 'Partenaires',
      type: 'item',
      url: PATH_ADMIN.partners,
    },
    {
      icon: icons.MovingOutlined,
      id: 'admin-trips',
      title: 'Trajets',
      type: 'item',
      url: PATH_ADMIN.trips.list,
    },
    {
      icon: icons.GradeOutlined,
      id: 'admin-rewards',
      title: 'Récompenses',
      type: 'item',
      url: PATH_ADMIN.rewards,
    },
  ],
  id: 'other',
  roles: [USER_ROLES.ADMIN],
  title: 'Administration',
  type: 'group',
};

export default administration;
