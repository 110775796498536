import { Dialog as MuiDialog, styled } from '@mui/material';
import React from 'react';

function StyledDialog({ children, ...props }) {
  const Dialog = styled(MuiDialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      margin: theme.spacing(2),
      padding: theme.spacing(3),
      width: '100%',
    },
  }));

  return <Dialog {...props}>{children}</Dialog>;
}

export default StyledDialog;
