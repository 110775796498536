import React, { lazy } from 'react';
import { Navigate } from 'react-router';
import Loadable from 'src/components/Loadable';
import WithBonuses from 'src/components/WithBonuses';
import MainLayout from 'src/layout/MainLayout';
import AuthGuard from 'src/utils/route-guard/AuthGuard';

import { APP_ROOT_PATH } from '../paths';

const HomePage = Loadable(lazy(() => import('src/pages/Home')));
const WelcomePage = Loadable(lazy(() => import('src/pages/Welcome')));
const MeRootPage = Loadable(lazy(() => import('src/pages/users/me/Root')));
const ProfilePage = Loadable(lazy(() => import('src/pages/users/me/Profile')));
const MeVerifyPage = Loadable(lazy(() => import('src/pages/users/me/Verify')));
const SponsorPage = Loadable(lazy(() => import('src/pages/users/me/sponsor/Sponsor')));
const SponsorListPage = Loadable(lazy(() => import('src/pages/users/me/sponsor/SponsorList')));
const WalletPage = Loadable(lazy(() => import('src/pages/users/me/Wallet')));
const BonusesPage = Loadable(lazy(() => import('src/pages/users/me/Bonuses')));
const AlertsPage = Loadable(lazy(() => import('src/pages/users/Alerts')));
const UserProfilePage = Loadable(lazy(() => import('src/pages/users/UserProfile')));

const ApplicationRoutes = {
  children: [
    {
      element: <HomePage />,
      index: true,
    },
    {
      element: <WelcomePage />,
      path: 'welcome',
    },
    {
      children: [
        {
          element: <MeRootPage />,
          path: '',
        },
        {
          element: <ProfilePage />,
          path: 'profile',
        },
        {
          element: <MeVerifyPage />,
          path: 'verify',
        },
        {
          children: [
            {
              element: <SponsorPage />,
              path: '',
            },
            {
              element: <SponsorListPage />,
              path: 'list',
            },
          ],
          path: 'sponsor',
        },
        {
          element: <WalletPage />,
          path: 'wallet',
        },
        {
          element: (
            <WithBonuses returning={<Navigate to={APP_ROOT_PATH} />}>
              <BonusesPage />
            </WithBonuses>
          ),
          path: 'bonuses',
        },
      ],
      path: 'me',
    },
    {
      element: <AlertsPage />,
      path: 'alerts',
    },
    {
      children: [
        {
          element: <UserProfilePage />,
          path: ':id',
        },
      ],
      path: 'users',
    },
  ],
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  path: '',
};

export default ApplicationRoutes;
