import {
  AccountBoxOutlined,
  LogoutOutlined,
  QrCodeOutlined,
  RedeemOutlined,
  VerifiedOutlined,
  WalletOutlined,
} from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import IconButton from 'src/components/@extended/IconButton';
import Transitions from 'src/components/@extended/Transitions';
import HelpLink from 'src/components/HelpLink';
import MainCard from 'src/components/MainCard';
import UserAvatar from 'src/components/users/UserAvatar';
import WithBonuses from 'src/components/WithBonuses';
import WithRoles from 'src/components/WithRoles';
import { ThemeMode } from 'src/config';
import USER_ROLES from 'src/constants/userRoles';
import useAuth from 'src/contexts/auth/useAuth';
import { PATH_USER } from 'src/routes/paths';
import Notifications from 'src/sections/notifications';

function TabPanel({ children, index, value, ...other }) {
  return (
    <div
      aria-labelledby={`profile-tab-${index}`}
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  index: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
};

function Profile() {
  const theme = useTheme();

  const { logout, user } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      //
    }
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'primary.light';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Stack alignItems="center" direction="row" spacing={1.5}>
        <WithRoles accessibleRoles={USER_ROLES.BASIC} excludeHierarchy>
          <Notifications />
        </WithRoles>
        <ButtonBase
          ref={anchorRef}
          aria-controls={open ? 'profile-grow' : undefined}
          aria-haspopup="true"
          aria-label="open profile"
          onClick={handleToggle}
          sx={{
            '&:focus-visible': {
              outline: `2px solid ${theme.palette.secondary.dark}`,
              outlineOffset: 2,
            },
            '&:hover': {
              bgcolor:
                theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'primary.lighter',
            },
            bgcolor: open ? iconBackColorOpen : 'transparent',
            borderRadius: 1,
            p: 0.25,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2} sx={{ p: 0.5 }}>
            <UserAvatar alt="profile user" showPartnerAvatar size="xs" user={user} />
          </Stack>
        </ButtonBase>
      </Stack>
      <Popper
        anchorEl={anchorRef.current}
        disablePortal
        open={open}
        placement="bottom-end"
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Transitions in={open} position="top-right" type="grow" {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                maxWidth: 290,
                minWidth: 275,
                width: 290,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 275,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} content={false} elevation={0}>
                  <CardContent sx={{ pt: 3, px: 2.5 }}>
                    <Grid alignItems="center" container justifyContent="space-between">
                      <Grid item>
                        <Stack
                          alignItems="center"
                          component={RouterLink}
                          direction="row"
                          onClick={() => setOpen(false)}
                          spacing={1.25}
                          sx={{ color: 'text.primary', textDecoration: 'none' }}
                          to={PATH_USER.me.root}
                        >
                          <UserAvatar
                            alt="profile user"
                            showPartnerAvatar
                            sx={{ height: 32, width: 32 }}
                            user={user}
                          />
                          <Stack sx={{ maxWidth: 115, overflow: 'hidden' }}>
                            <Typography noWrap variant="h6">
                              {user?.firstname} {user?.lastname}
                            </Typography>
                            <WithRoles accessibleRoles={USER_ROLES.BASIC}>
                              <Typography color="textSecondary" noWrap variant="body2">
                                +33 {user.phone}
                              </Typography>
                            </WithRoles>
                            <WithRoles accessibleRoles={USER_ROLES.ADMIN}>
                              <Typography color="textSecondary" noWrap variant="body2">
                                {user.email}
                              </Typography>
                            </WithRoles>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Déconnexion">
                          <IconButton
                            onClick={handleLogout}
                            size="large"
                            sx={{ color: 'text.primary' }}
                          >
                            <LogoutOutlined />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <WithRoles accessibleRoles={USER_ROLES.BASIC}>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <List>
                      <ListItemButton
                        LinkComponent={RouterLink}
                        onClick={() => setOpen(false)}
                        to={PATH_USER.me.profile}
                      >
                        <ListItemIcon>
                          <AccountBoxOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Mon profil" />
                      </ListItemButton>
                      <ListItemButton
                        LinkComponent={RouterLink}
                        onClick={() => setOpen(false)}
                        to={PATH_USER.me.verify}
                      >
                        <ListItemIcon>
                          <VerifiedOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Vérifie ton profil" />
                      </ListItemButton>

                      <WithBonuses>
                        <ListItemButton
                          LinkComponent={RouterLink}
                          onClick={() => setOpen(false)}
                          to={PATH_USER.me.sponsor}
                        >
                          <ListItemIcon>
                            <QrCodeOutlined />
                          </ListItemIcon>
                          <ListItemText primary="Parrainage" />
                        </ListItemButton>
                      </WithBonuses>

                      <ListItemButton
                        LinkComponent={RouterLink}
                        onClick={() => setOpen(false)}
                        to={PATH_USER.me.wallet}
                      >
                        <ListItemIcon>
                          <WalletOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Wallet" />
                      </ListItemButton>
                      <WithBonuses>
                        <ListItemButton
                          LinkComponent={RouterLink}
                          onClick={() => setOpen(false)}
                          to={PATH_USER.me.bonuses}
                        >
                          <ListItemIcon>
                            <RedeemOutlined />
                          </ListItemIcon>
                          <ListItemText primary="Primes" />
                        </ListItemButton>
                      </WithBonuses>
                    </List>
                  </WithRoles>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                  <Stack alignItems="center" p={3}>
                    <HelpLink />
                  </Stack>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
}

export default Profile;
