function getPlatformInfo() {
  const useApp = Boolean(window.ReactNativeWebView);

  function getPlatform() {
    return useApp ? 'app' : 'web';
  }

  function getOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return 'android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'ios';
    }
    if (/Macintosh/i.test(userAgent) && !/ipad/i.test(userAgent)) {
      return 'mac';
    }
    if (/Windows/i.test(userAgent)) {
      return 'windows';
    }
    if (/Linux/i.test(userAgent)) {
      return 'linux';
    }

    return 'unknown';
  }

  function getDeviceType() {
    const userAgent = navigator.userAgent.toLowerCase();
    const platform = navigator.platform.toLowerCase();

    const isMobile = /mobile|android|iphone|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    const isTablet = /tablet|ipad|playbook|silk/i.test(userAgent);

    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    const isDesktop = /macintosh|windows|linux/.test(platform);

    if (
      isTablet ||
      (isTouchDevice && /macintosh/i.test(platform) && 'ontouchstart' in document.documentElement)
    ) {
      return 'tablet';
    }

    if (isMobile && !isTablet) {
      return 'mobile';
    }

    if (isDesktop && !isTouchDevice) {
      return 'desktop';
    }

    return 'mobile';
  }

  const platform = getPlatform();

  const os = getOS();

  const deviceType = getDeviceType();

  return `${deviceType}_${os}_${platform}`;
}

export default getPlatformInfo;
