import { useLazyQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { GET_REWARDS_LANDING_INFORMATION } from 'src/graphql/landing/queries';

const LandingInformationContext = createContext();

const useLandingInformation = () => useContext(LandingInformationContext);

function LandingInformationProvider({ children }) {
  const [
    getRewardsLandingInformations,
    { data: getRewardsLandingInformationsData, ...getRewardsLandingInformationsState },
  ] = useLazyQuery(GET_REWARDS_LANDING_INFORMATION, {
    fetchPolicy: 'network-only',
  });

  const memoizedContextValue = useMemo(
    () => ({
      getRewardsLandingInformations,
      getRewardsLandingInformationsState,
      rewardsLandingInformations:
        getRewardsLandingInformationsData?.getRewardsLandingPageInformations || {},
    }),
    [
      getRewardsLandingInformationsState,
      getRewardsLandingInformationsData,
      getRewardsLandingInformations,
    ]
  );

  return (
    <LandingInformationContext.Provider value={memoizedContextValue}>
      {children}
    </LandingInformationContext.Provider>
  );
}

export { LandingInformationProvider, useLandingInformation };
