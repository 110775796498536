import { Divider, Link, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import HelpLink from 'src/components/HelpLink';
import { useTutorialContext } from 'src/components/tutorial/TutorialContext';
import USER_ROLES, { isGranted } from 'src/constants/userRoles';
import useAuth from 'src/contexts/auth/useAuth';
import useResponsive from 'src/hooks/useResponsive';
import { PATH_USER } from 'src/routes/paths';
import { WELCOME_TUTORIAL_NAME } from 'src/sections/tutorials/welcome-tutorial/WelcomeTutorial';
import { dispatch } from 'src/store';
import { openDrawer } from 'src/store/reducers/menu';

import AnimateButton from '../../../../components/@extended/AnimateButton';
import MainCard from '../../../../components/MainCard';

function NavCard() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'md');
  const { handleOpenTutorial } = useTutorialContext();

  const handleShowTutorial = useCallback(() => {
    navigate(PATH_USER.home);
    if (isMobile) {
      dispatch(openDrawer(false));
    }
    setTimeout(() => {
      handleOpenTutorial(WELCOME_TUTORIAL_NAME);
    }, 500);
  }, [handleOpenTutorial, isMobile, navigate]);

  if (!isGranted(user.role, USER_ROLES.BASIC)) return null;

  return (
    <MainCard sx={{ bgcolor: 'grey.50', m: 3 }}>
      <Stack alignItems="center" spacing={2}>
        <Stack alignItems="center">
          <Typography variant="h5">Besoin d&apos;aide?</Typography>
          <Typography color="secondary" variant="h6">
            Nos équipes sont là
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <AnimateButton>
            <HelpLink isButton label="Contacte-nous" />
          </AnimateButton>
          <Divider>ou</Divider>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link component="button" onClick={handleShowTutorial} variant="caption">
            Voir le tutoriel
          </Link>
        </Stack>
      </Stack>
    </MainCard>
  );
}

export default NavCard;
