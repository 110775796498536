import { gql } from '@apollo/client';

const GET_REWARDS_LANDING_INFORMATION = gql`
  query getRewardsLandingPageInformations {
    getRewardsLandingPageInformations {
      initialRewardForUser
      lastRewardForUser
      maxInitialRewardCount
      maxLastRewardCount
      maxTripsPerPeriod
      numberOfMonthsToTrack
      rewardForSponsor
      rewardForSponsored
      withBoost
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_REWARDS_LANDING_INFORMATION };
