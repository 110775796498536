import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import LoadingLabeledButton from 'src/components/LoadingLabeledButton';
import { useLocation } from 'src/contexts/LocationContext';
import { useTrip } from 'src/contexts/TripContext';
import useDialogs from 'src/hooks/useDialogs';
import useDynamicLabel from 'src/hooks/useDynamicLabel';

function EndTripAction({ trip }) {
  const { endTrip, endTripState } = useTrip();
  const { alert, confirm } = useDialogs();
  const { dynamicLabelOptions, getCurrentLocation } = useLocation();
  const [localLoading, setLocalLoading] = useState(false);

  const handleEndTrip = async () => {
    try {
      setLocalLoading(true);
      if (
        !(await confirm({
          confirmLabel: "Confirmer l'arrivée",
          message: "Ca y est, tout le monde est arrivé! On espère que tout s'est bien passé!",
          title: 'Yeeaah!',
        }))
      ) {
        setLocalLoading(false);
        return;
      }

      const currentLocation = await getCurrentLocation();

      if (!currentLocation) {
        await alert({
          message:
            "Nous n'avons pas réussi a relever ta position, pense à activer la localisation!",
          title: 'Whoups!',
        });

        setLocalLoading(false);
        return;
      }

      await endTrip({
        variables: {
          input: {
            endingGpsAddress: currentLocation,
            tripId: trip.id,
          },
        },
      });
      setLocalLoading(false);
    } catch {
      setLocalLoading(false);
    }
  };

  const { handleClick: handleEndTripWithDynamicLabel, label } = useDynamicLabel(
    handleEndTrip,
    'Bien arrivé!',
    dynamicLabelOptions
  );

  const usersInCar = trip.journeys.filter(
    ({ hasConfirmedTakingCharge, hasDeclinedTakingCharge }) =>
      hasConfirmedTakingCharge || !hasDeclinedTakingCharge
  );

  const allUsersInCarHaveConfirmedDeposit =
    !usersInCar.length ||
    (Boolean(usersInCar.length) &&
      usersInCar.every(
        ({ hasConfirmedDeposit, hasDeclinedTakingCharge }) =>
          hasConfirmedDeposit || hasDeclinedTakingCharge
      ));

  return (
    <Stack spacing={1}>
      {allUsersInCarHaveConfirmedDeposit && (
        <LoadingLabeledButton
          loading={endTripState.loading || localLoading}
          onClick={handleEndTripWithDynamicLabel}
          variant="contained"
        >
          {label}
        </LoadingLabeledButton>
      )}
      <Typography align="center" color="text.secondary" variant="caption">
        Tu pourras terminer le trajet quand tous les passagers auront confirmé leur arrivée
      </Typography>
    </Stack>
  );
}

export default EndTripAction;
