import { EditOutlined } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import React from 'react';
import { useLocation, useMatch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import earnMoneyAnimationData from 'src/assets/animations/earn-money.json';
import MobileSheet from 'src/components/MobileSheet';
import PageWrapper from 'src/components/PageWrapper';
import useAuth from 'src/contexts/auth/useAuth';
import { PATH_USER } from 'src/routes/paths';

import AnimationWrapper from '../onboardings/pre-signup-onboarding/AnimationWrapper';

function SwornCertificatePopupSheet() {
  const { user } = useAuth();
  const location = useLocation();
  const isBonusesPage = useMatch(PATH_USER.me.bonuses, location);

  const {
    initialRewardsInformations: { short },
  } = user;

  const {
    hasSignedSwornStatement: hasSignedSwornStatementShort,
    needSwornStatement: needSwornStatementShort,
  } = short;

  const shouldSignForShort =
    needSwornStatementShort && !hasSignedSwornStatementShort && !isBonusesPage;

  return (
    <MobileSheet height="auto" notSwipeable open={shouldSignForShort} variant="card">
      <PageWrapper
        subtitle="Signe rapidement ton attestation et débloque ta prime"
        title="On y est!"
        withoutLogo
      />
      <Stack spacing={2}>
        <Box px={6}>
          <AnimationWrapper animationData={earnMoneyAnimationData} withBackground />
        </Box>
        <Button
          endIcon={<EditOutlined />}
          LinkComponent={RouterLink}
          to={`${PATH_USER.me.bonuses}?certificate-form-open=true`}
          variant="outlined"
        >
          Signer mon attestation
        </Button>
      </Stack>
    </MobileSheet>
  );
}

export default SwornCertificatePopupSheet;
