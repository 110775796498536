import { Stack, Typography } from '@mui/material';
import React from 'react';
import carPoolingAnimationData from 'src/assets/animations/car-pooling.json';

import AnimationWrapper from '../AnimationWrapper';

function WelcomeStepContent() {
  return (
    <Stack alignItems="center" spacing={3}>
      <Stack>
        <AnimationWrapper animationData={carPoolingAnimationData} animationProps={{ loop: true }} />
      </Stack>
      <Typography align="center" variant>
        Découvre une nouvelle façon de te déplacer au quotidien tout en réduisant ton empreinte
        carbone.
      </Typography>
    </Stack>
  );
}

export default WelcomeStepContent;
