import { StarBorderOutlined } from '@mui/icons-material';
import { AvatarGroup, CircularProgress } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import useAuth from 'src/contexts/auth/useAuth';
import { PATH_USER } from 'src/routes/paths';

import getAvatarName from '../../utils/getAvatarName';
import Avatar from '../@extended/Avatar';

function WithPartnerWrapper({ children, isPartner, showPartnerAvatar, size }) {
  if (!(showPartnerAvatar && isPartner)) return children;

  return (
    <AvatarGroup>
      {children}
      <Avatar color="warning" size={size} type="combined">
        <StarBorderOutlined />
      </Avatar>
    </AvatarGroup>
  );
}

function UserAvatar({ children, loading, showPartnerAvatar, size, sx, user, withLink, ...props }) {
  const { isPartner } = useAuth();
  const navigate = useNavigate();

  const avatar = user?.avatar;
  const firstname = user?.firstname;

  const handleClickAvatar = useCallback(() => {
    if (withLink) {
      navigate(PATH_USER.userProfile(user.id));
    }
  }, [navigate, user.id, withLink]);

  return (
    <WithPartnerWrapper isPartner={isPartner} showPartnerAvatar={showPartnerAvatar} size={size}>
      <Avatar
        color="primary"
        onClick={handleClickAvatar}
        size={size}
        src={!loading && avatar}
        type="outlined"
        {...props}
        sx={{
          ...(withLink && {
            cursor: 'pointer',
          }),
          ...(sx && sx),
        }}
      >
        {loading ? (
          <CircularProgress size={14} />
        ) : (
          <>
            {children && children}
            {!children && getAvatarName(firstname)}
          </>
        )}
      </Avatar>
    </WithPartnerWrapper>
  );
}

UserAvatar.defaultProps = {
  size: 'sm',
};

export default UserAvatar;
