import { alpha, styled } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

const Backdrop = styled(motion.div)(({ theme }) => ({
  background: alpha(theme.palette.background.default, 0.6),
  position: 'fixed',
  zIndex: 9999,
}));

const HighlightedElement = styled(motion.div)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderRadius: `${theme.shape.borderRadius / 2}px`,
  borderStyle: 'solid',
  borderWidth: 1,
  pointerEvents: 'all',
  position: 'fixed',
  zIndex: 10_000,
}));

const SPACING = 0;

const getValue = (value) => Math.max(value, 2);

function TutorialBackdrop({ dimensions }) {
  const width = window.innerWidth;
  const height = window.innerHeight;

  return (
    <>
      <HighlightedElement
        animate={{
          height: getValue(dimensions.height + SPACING * 2),
          left: getValue(dimensions.left - SPACING),
          top: getValue(dimensions.top - SPACING),
          width: getValue(dimensions.width + SPACING * 2),
        }}
      />
      {/* Left Panel */}
      <Backdrop
        animate={{
          height: '100%',
          left: 0,
          top: 0,
          width: getValue(dimensions.left - SPACING),
        }}
      />
      {/* Right Panel */}
      <Backdrop
        animate={{
          height: '100%',
          left: getValue(dimensions.left + dimensions.width + SPACING),
          top: 0,
          width: getValue(width - (dimensions.left + dimensions.width)),
        }}
      />
      {/* Top Panel */}
      <Backdrop
        animate={{
          height: getValue(dimensions.top - SPACING),
          left: getValue(dimensions.left - SPACING),
          top: 0,
          width: getValue(dimensions.width + 2 * SPACING),
        }}
      />
      {/* Bottom Panel */}
      <Backdrop
        animate={{
          height: getValue(height - dimensions.top - 2 * SPACING - dimensions.height),
          left: getValue(dimensions.left - SPACING),
          top: getValue(dimensions.top + dimensions.height + SPACING),
          width: getValue(dimensions.width + 2 * SPACING),
        }}
      />
    </>
  );
}

export default TutorialBackdrop;
