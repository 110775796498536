import { gql } from '@apollo/client';

import { userFragment } from '../fragments';

const GET_MY_PRE_ELIGIBILITY = gql`
  query getMyPreEligibility {
    getMyPreEligibility {
      isRpcPreCheckValidForShortDistance
      isRpcPreCheckValidForLongDistance
    }
  }
`;

const GET_USER_BY_ID = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      ...User
    }
  }
  ${userFragment}
`;

const CAN_RATE_USER = gql`
  query canRateUser($userId: ID!) {
    canRateUser(userId: $userId)
  }
`;

export { GET_MY_PRE_ELIGIBILITY, GET_USER_BY_ID, CAN_RATE_USER };
