import { BugReportOutlined } from '@mui/icons-material';
import { Box, Chip, styled } from '@mui/material';
import React from 'react';
import config from 'src/config/index';

const { showEnvIndicator } = config;

const EnvIndicatorWrapper = styled(Box)(({ theme }) => ({
  left: '50%',
  position: 'fixed',
  top: theme.spacing(2.25),
  transform: 'translate(-50%)',
  zIndex: theme.zIndex.modal + 1,
}));

function EnvIndicator() {
  if (!showEnvIndicator) return null;

  const env = process.env.REACT_APP_ENV || 'development';

  return (
    <EnvIndicatorWrapper>
      <Chip
        color="warning"
        icon={<BugReportOutlined sx={{ fontSize: 14 }} />}
        label={env}
        size="small"
        sx={{
          borderRadius: (theme) => theme.shape.borderRadius,
          boxShadow: (theme) => theme.shadows[3],
        }}
        variant="combined"
      />
    </EnvIndicatorWrapper>
  );
}

export default EnvIndicator;
