const WELCOME_TUTORIAL_STEPS = [
  {
    content:
      'Trouve facilement ton prochain trajet ! Indique ton point de départ, ta destination, la date et l’heure souhaitée, et découvre toutes les options de covoiturage disponibles. C’est rapide et pratique !',
    id: 'tutorial:search-trip',
    title: 'Trouve ton trajet',
  },
  {
    content:
      'Ajoute ton départ, arrivée, date, heure, le nombre de voyageurs, le prix, la voiture, et même la fréquence du trajet si c’est récurrent. Parfait pour les trajets quotidiens !',
    id: 'tutorial:create-trip',
    title: 'Publie un trajet',
  },
  {
    content:
      'En un clic, localise-toi pour voir les trajets disponibles autour de toi. Idéal pour les départs spontanés et les trajets de dernière minute !',
    id: 'tutorial:around-me-trips',
    placement: 'left',
    title: 'Les trajets autour de moi',
  },
  {
    clickBefore: 'tutorial:before:toggle-menu',
    content:
      'Crée des alertes personnalisées selon tes critères et sois immédiatement notifié dès qu’un trajet correspond à ta recherche. Ne rate aucun trajet qui t’intéresse',
    id: 'tutorial:alerts',
    title: 'Alertes de trajet',
  },
  {
    clickBefore: 'tutorial:profile',
    content:
      'Obtiens ton code parrain, partage-le facilement et invite tes contacts. Profite de notre programme de parrainage et fais découvrir l’appli à ton entourage !',
    id: 'tutorial:sponsor',
    title: 'Parraine tes amis',
  },
  {
    content:
      'Accède au suivi de tes primes et signe ton attestation après ton premier trajet éligible. Un petit coup de pouce pour te récompenser !',
    id: 'tutorial:bonuses',
    placement: 'top',
    title: 'Garde un oeil sur tes Primes',
  },
  {
    content:
      'Tu connais maintenant les principales fonctionnalités ! Prends le temps de découvrir les nombreux recoins de l’appli pour optimiser ton expérience de covoiturage et trouver des trajets en toute simplicité',
    id: 'tutorial:end',
    placement: 'top',
    title: 'Félicitations !',
  },
];

export default WELCOME_TUTORIAL_STEPS;
