import { Stack, Typography } from '@mui/material';
import React from 'react';
import signUpAnimationData from 'src/assets/animations/signup.json';

import AnimationWrapper from '../AnimationWrapper';

function SignUpStepContent() {
  return (
    <Stack alignItems="center" spacing={3}>
      <AnimationWrapper
        animationData={signUpAnimationData}
        animationProps={{ loop: false }}
        withBackground
      />
      <Typography align="center" variant>
        Inscris-toi en quelques secondes et accède à une large communauté de voyageurs
      </Typography>
    </Stack>
  );
}

export default SignUpStepContent;
