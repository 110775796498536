import { gql } from '@apollo/client';

import { fileFragment } from '../fragments';

const GET_SWORN_STATEMENT = gql`
  query getSwornStatement {
    getSwornStatement {
      ...File
    }
  }
  ${fileFragment}
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_SWORN_STATEMENT };
