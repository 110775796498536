import { gql } from '@apollo/client';

import { addressFragment, ratingFragment, tripFragment } from '../fragments';

const GET_MY_PENDING_RATINGS = gql`
  query getMyPendingRatings {
    getMyPendingRatings {
      myPendingRatingsAsPassenger {
        ...Rating
        trip {
          ...Trip
          startAddress {
            ...Address
          }
          endAddress {
            ...Address
          }
          journeys {
            id
            passenger {
              id
              firstname
              avatar
            }
          }
        }
        passenger {
          id
          firstname
          avatar
        }
        conductor {
          id
          firstname
          avatar
        }
      }
      myPendingRatingsAsConductor {
        ...Rating
        trip {
          ...Trip
          startAddress {
            ...Address
          }
          endAddress {
            ...Address
          }
          journeys {
            id
            passenger {
              id
              firstname
              avatar
            }
          }
        }
        passenger {
          id
          firstname
          avatar
        }
        conductor {
          id
          firstname
          avatar
        }
      }
    }
  }
  ${ratingFragment}
  ${tripFragment}
  ${addressFragment}
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_MY_PENDING_RATINGS };
