import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';

import useLocales from '../../../hooks/useLocales';
import useQueryErrors from './useQueryErrors';

// eslint-disable-next-line default-param-last
const useSafeMutation = (query, queryOptions = {}, errorsOptions) => {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const {
    displaySnackbar = true,
    onCompleted: customOnCompleted,
    ...restQueryOptions
  } = queryOptions;

  const onCompleted = useCallback(
    (data) => {
      if (customOnCompleted) {
        customOnCompleted(data);
      }
      if (displaySnackbar) {
        enqueueSnackbar(translate('commons.snackbarActionsSuccess'));
      }
    },
    [customOnCompleted, displaySnackbar, enqueueSnackbar, translate]
  );

  const [mutation, { error, ...queryResult }] = useMutation(query, {
    ...restQueryOptions,
    onCompleted,
  });

  const errorOptions = useMemo(() => ({ silentError: false, ...errorsOptions }), [errorsOptions]);

  useQueryErrors(error, errorOptions);

  return [mutation, { error, ...queryResult }];
};

export default useSafeMutation;
