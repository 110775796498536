import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

const OnboardingContext = createContext();

const useOnboarding = () => useContext(OnboardingContext);

function OnboardingProvider({ children }) {
  const [onboardings, setOnboardings] = useState({});

  const setOpen = useCallback(
    (onboardingName, open) =>
      setOnboardings((oldOnboardings) => ({ ...oldOnboardings, [onboardingName]: open })),
    []
  );

  const memoizedContextValue = useMemo(
    () => ({
      onboardings,
      setOpen,
    }),
    [onboardings, setOpen]
  );

  return (
    <OnboardingContext.Provider value={memoizedContextValue}>{children}</OnboardingContext.Provider>
  );
}

export { OnboardingProvider, useOnboarding };
