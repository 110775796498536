import { Button, CircularProgress } from '@mui/material';
import React from 'react';

function LoadingLabeledButton({ children, disabled, loading, ...props }) {
  return (
    <Button
      {...props}
      disabled={disabled || loading}
      startIcon={loading && <CircularProgress color="inherit" size={14} />}
    >
      {children}
    </Button>
  );
}

export default LoadingLabeledButton;
