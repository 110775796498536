import React, { lazy } from 'react';

import Loadable from '../../components/Loadable';

const WalletSecureModeReturnPage = Loadable(
  lazy(() => import('../../pages/security/WalletSecureModeReturn'))
);

const SecurityRoutes = {
  element: <WalletSecureModeReturnPage />,
  path: 'wallet/mango-pay/return',
};

export default SecurityRoutes;
