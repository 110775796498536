import { useTheme } from '@emotion/react';
import { RefreshOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Checkbox, IconButton, Stack, Typography, alpha, styled } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import Lottie from 'lottie-react';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Webcam from 'react-webcam';
import avatarAnimationData from 'src/assets/animations/avatar.json';
import useAuth from 'src/contexts/auth/useAuth';
import { useFile } from 'src/contexts/FileContext';
import useDialogs from 'src/hooks/useDialogs';
import base64ToFile from 'src/utils/base64ToFile';
import isAndroidDevice from 'src/utils/isAndroidDevice';
import roundDecimals from 'src/utils/roundDecimals';

const SIZE = 180;
const BORDER_SIZE = 2;

const VisuallyHiddenInput = styled('input')({
  bottom: 0,
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  left: 0,
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: 1,
});

function Step6Content({ setNextDisabled, setOnClick }) {
  const { alert, confirm } = useDialogs();
  const { refetchUser, user } = useAuth();
  const theme = useTheme();
  const cameraEmplacementRef = useRef();
  const cameraRef = useRef();
  const [takingPhoto, setTakingPhoto] = useState(false);
  const [base64File, setBase64File] = useState(null);
  const [setAsAvatar, setSetAsAvatar] = useState(true);
  const [permissionStatus, setPermissionStatus] = useState('prompt');
  const isInWebView = useMemo(() => Boolean(window.ReactNativeWebView), []);
  const uploadDocumentInputRef = useRef();
  const shouldUploadInsteadOfLivePhoto = isInWebView && isAndroidDevice();

  const isCameraPortraitMode = window.innerHeight > window.innerWidth;

  const { uploadSelfie } = useFile();

  const handlePermissionDenied = useCallback(async () => {
    setPermissionStatus('denied');
    const dialog = isInWebView ? confirm : alert;
    await dialog({
      confirmLabel: "J'ai compris",
      message: "La caméra est désactivée. Rends toi dans tes paramètres pour l'activer",
      title: 'Oups!',
      ...(isInWebView && {
        confirmLabel: 'Paramètres',
        discardLabel: 'Plus tard',
        handleConfirm: window.openSettings,
      }),
    });

    return false;
  }, [alert, confirm, isInWebView]);

  const showCamera = useCallback(async () => {
    if (!isInWebView) return;

    const { left, top } = cameraEmplacementRef.current.getBoundingClientRect();

    const showArgs = {
      left: roundDecimals(left + BORDER_SIZE, 0),
      size: SIZE - 2 * BORDER_SIZE,
      top: roundDecimals(top + BORDER_SIZE, 0),
    };

    await window.camera.show(showArgs);
  }, [isInWebView]);

  const handleAskCameraPermissions = useCallback(async () => {
    try {
      const defaultPermissions = await window.camera.checkPermissions();

      if (defaultPermissions.state === 'denied') return handlePermissionDenied();

      if (defaultPermissions.state !== 'granted') {
        await alert({
          confirmLabel: 'Ok',
          message: "Nous avons besoin d'accéder à ta caméra!",
          noCloseAction: true,
          title: 'Active ta caméra',
        });

        await window.camera.requestPermissions();
      }
      const checkPermissions = await window.camera.checkPermissions();

      if (checkPermissions.state === 'granted') {
        setPermissionStatus('granted');
        if (!shouldUploadInsteadOfLivePhoto) {
          await showCamera();
        }
        return true;
      }

      if (checkPermissions.state === 'denied') return handlePermissionDenied();

      return true;
    } catch (error) {
      // eslint-disable-next-line no-return-await
      return handlePermissionDenied();
    }
  }, [alert, handlePermissionDenied, shouldUploadInsteadOfLivePhoto, showCamera]);

  const takePhoto = useCallback(async () => {
    if (permissionStatus !== 'granted') {
      handleAskCameraPermissions();
      return;
    }

    setTakingPhoto(true);
    let imageBase64 = null;

    try {
      if (isInWebView) {
        imageBase64 = await window.camera.takePhoto();

        window.camera.hide();
      } else {
        imageBase64 = await cameraRef.current.getScreenshot();
      }
      setBase64File(imageBase64);
      setTakingPhoto(false);
      setNextDisabled(false);
    } catch {
      setTakingPhoto(false);
      enqueueSnackbar('La prise de photo a échoué! Essaie à nouveau', { variant: 'error' });
    }
  }, [permissionStatus, isInWebView, handleAskCameraPermissions, setNextDisabled]);

  const handleReTakePhoto = useCallback(async () => {
    setBase64File(null);
    setNextDisabled(true);
    await showCamera();
  }, [showCamera, setNextDisabled]);

  useEffect(() => {
    if (user.selfie) return () => null;

    setTimeout(() => {
      setNextDisabled(true);
    }, 1);

    (async () => {
      await handleAskCameraPermissions();
    })();

    return () => {
      if (isInWebView) window.camera.hide();
    };
  }, [handleAskCameraPermissions, isInWebView, setNextDisabled, user.selfie]);

  const videoConstraints = {
    facingMode: 'user',
  };

  const webcamStyle = {
    height: '100%',
    objectFit: 'cover',
    width: 'auto',
    ...(isCameraPortraitMode && {
      height: 'auto',
      width: '100%',
    }),
    ...(isInWebView && {
      transform: 'scaleX(-1)',
    }),
  };

  const handleConfirmPhoto = useCallback(async () => {
    if (user.selfie) return;

    await uploadSelfie({
      onCompleted: async () => {
        await refetchUser();
        setBase64File(null);
        setNextDisabled(false);
      },
      variables: {
        input: {
          file: base64ToFile(base64File, isInWebView ? 'selfie.jpg' : 'selfie.png'),
          setAsAvatar,
        },
      },
    });
  }, [base64File, isInWebView, refetchUser, setAsAvatar, uploadSelfie, setNextDisabled, user]);

  useEffect(() => {
    setOnClick(() => handleConfirmPhoto);
  }, [handleConfirmPhoto, setOnClick]);

  const handleChoosePhoto = useCallback(() => {
    uploadDocumentInputRef.current.click();
  }, []);

  const handleUploadChosenPhoto = useCallback(
    (event) => {
      const photo = event.target.files[0];

      if (!photo) return;
      // Turn it into base64 file
      const reader = new FileReader();

      reader.onload = function (e) {
        setBase64File(e.target.result);
        setNextDisabled(false);
      };

      reader.readAsDataURL(photo);
    },
    [setNextDisabled]
  );

  const hasCameraAccess = useMemo(() => permissionStatus === 'granted', [permissionStatus]);

  return (
    <Stack alignItems="center" flexGrow={1} spacing={2}>
      <Box position="relative">
        <Box bottom={theme.spacing(1)} position="absolute" right={theme.spacing(1)} zIndex={1}>
          <AnimatePresence mode="wait">
            {base64File && (
              <motion.div
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                initial={{ scale: 0 }}
                transition={{ duration: 0.15 }}
              >
                <IconButton
                  onClick={handleReTakePhoto}
                  size="small"
                  sx={{
                    backgroundColor: 'common.black',
                    borderRadius: '100%',
                    color: 'common.white',
                  }}
                >
                  <RefreshOutlined />
                </IconButton>
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
        <Avatar
          ref={cameraEmplacementRef}
          sx={{
            border: `2px dashed ${theme.palette.primary.main}`,
            height: SIZE,
            overflow: 'hidden',
            position: 'relative',
            width: SIZE,
          }}
        >
          {(base64File || user.selfie) && (
            <img alt="selfie" src={base64File || user.selfie} style={webcamStyle} />
          )}
          {!user.selfie && !base64File && !shouldUploadInsteadOfLivePhoto && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                height: SIZE,
                justifyContent: 'center',
                left: -1,
                p: 2,
                pointerEvents: 'none',
                position: 'absolute',
                top: -1,
                width: SIZE,
                zIndex: 9,
              }}
            >
              <Box
                sx={{
                  border: `2px solid ${alpha(theme.palette.background.default, 0.5)}`,
                  borderRadius: '50% / 50%',
                  height: '95%',
                  pointerEvents: 'none',
                  width: SIZE / 2,
                }}
              />
            </Box>
          )}
          {!user.selfie &&
            hasCameraAccess &&
            !base64File &&
            !isInWebView &&
            !shouldUploadInsteadOfLivePhoto && (
              <Webcam
                ref={cameraRef}
                mirrored
                screenshotFormat="image/png"
                style={webcamStyle}
                videoConstraints={videoConstraints}
              />
            )}
          {!user.selfie && hasCameraAccess && !base64File && shouldUploadInsteadOfLivePhoto && (
            <Lottie animationData={avatarAnimationData} loop />
          )}
        </Avatar>
      </Box>
      <AnimatePresence mode="wait">
        <motion.div
          key={base64File}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
        >
          <Stack alignItems="center" spacing={0.5}>
            {!base64File && !user.selfie && !shouldUploadInsteadOfLivePhoto && (
              <LoadingButton
                loading={takingPhoto}
                onClick={takePhoto}
                size="small"
                variant="outlined"
              >
                Prendre une photo
              </LoadingButton>
            )}
            {!base64File && !user.selfie && shouldUploadInsteadOfLivePhoto && (
              <LoadingButton
                loading={takingPhoto}
                onClick={handleChoosePhoto}
                size="small"
                variant="outlined"
              >
                Prendre une photo
              </LoadingButton>
            )}
            {!base64File && !user.selfie && (
              <Typography align="center" color="error" variant="caption">
                Cette étape est obligatoire pour des raisons de sécurité
              </Typography>
            )}
            {base64File && (
              <Stack
                alignItems="center"
                direction="row"
                onClick={() => setSetAsAvatar(!setAsAvatar)}
                spacing={0.5}
              >
                <Checkbox checked={setAsAvatar} size="small" />
                <Typography variant="caption">Utiliser comme photo de profil</Typography>
              </Stack>
            )}
            {base64File &&
              (setAsAvatar ? (
                <Typography variant="caption">
                  La photo sera définie comme photo de profil
                </Typography>
              ) : (
                <Typography variant="caption">
                  Tu pourra définir ta photo de profil plus tard
                </Typography>
              ))}
            {user.selfie && (
              <Stack>
                <Typography align="center" variant="caption">
                  ✅ C&apos;est tout bon!
                </Typography>
                {user.avatar && (
                  <Typography align="center" variant="caption">
                    <strong>Cette photo a été définie comme photo de profil</strong>
                    <br />
                    Tu pourras la changer plus tard, dans la section <strong>Mon profil</strong>
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        </motion.div>
      </AnimatePresence>
      <VisuallyHiddenInput
        ref={uploadDocumentInputRef}
        accept="image/*"
        capture="user"
        onChange={handleUploadChosenPhoto}
        type="file"
      />
    </Stack>
  );
}

export default Step6Content;
