import { gql } from '@apollo/client';

const tripFragment = gql`
  fragment Trip on Trip {
    id

    conductorId

    passengerNumbers
    remainingPassengerNumbers
    price
    priceIncludingFee

    startDateTime

    estimatedDistanceInMeters
    estimatedDurationInMinutes

    hasDriverStarted
    hasDriverFinished

    distanceInMeters
    durationInMinutes

    isAborted

    canRateTrip

    createdAt
    updatedAt
  }
`;

export default tripFragment;
