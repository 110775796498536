import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

function useDynamicLabel(bind, defaultLabel, { delays, labels, loadingLabel }) {
  const [label, setLabel] = useState(defaultLabel);
  const [isExecuting, setIsExecuting] = useState(false);
  const timers = useRef([]);

  // Handler function to start the process manually
  const handler = useCallback(async () => {
    if (isExecuting || !bind || typeof bind !== 'function') return;

    setLabel(loadingLabel); // Set the label to the loading state
    setIsExecuting(true);

    // Start timers to update the label
    labels.forEach((currentLabel, index) => {
      const timeoutId = setTimeout(() => {
        setLabel(currentLabel);
      }, delays[index]);
      timers.current.push(timeoutId);
    });

    try {
      await bind(); // Execute the bound callback
    } finally {
      // Cleanup: Reset to default label and clear all timers
      timers.current.forEach(clearTimeout);
      timers.current = [];
      setIsExecuting(false);
      setLabel(defaultLabel);
    }
  }, [bind, isExecuting, labels, delays, loadingLabel, defaultLabel]);

  useEffect(
    () => () => {
      timers.current.forEach(clearTimeout);
      timers.current = [];
    },
    []
  );

  const hookResult = useMemo(
    () => ({ handleClick: handler, label, loading: isExecuting }),
    [handler, isExecuting, label]
  );

  return hookResult;
}

export default useDynamicLabel;
