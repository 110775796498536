import { DirectionsCarOutlined, MapOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Divider, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import React, { useState } from 'react';
import AbortTripOrJourneyConditions from 'src/components/AbortTripOrJourneyConditions';
import HelpLink from 'src/components/HelpLink';
import LoadingLabeledButton from 'src/components/LoadingLabeledButton';
import PageWrapper from 'src/components/PageWrapper';
import { useJourney } from 'src/contexts/JourneyContext';
import { useLocation } from 'src/contexts/LocationContext';
import useDialogs from 'src/hooks/useDialogs';
import useDynamicLabel from 'src/hooks/useDynamicLabel';

function WaitingForDecision({ journey, trip }) {
  const {
    confirmTakingCharge,
    confirmTakingChargeState,
    declineTakingCharge,
    declineTakingChargeState,
  } = useJourney();
  const { alert, confirm } = useDialogs();
  const { dynamicLabelOptions, getCurrentLocation } = useLocation();
  const [localLoading, setLocalLoading] = useState(false);

  const handleConfirmTakingCharge = async () => {
    try {
      setLocalLoading(true);
      if (
        !(await confirm({
          confirmLabel: 'Confirmer',
          message:
            "Avant de confirmer la prise en charge, assures-toi d'être en compagnie du conducteur",
          title: 'Confirmation',
        }))
      ) {
        setLocalLoading(false);
        return;
      }

      const currentLocation = await getCurrentLocation();

      if (!currentLocation) {
        await alert({
          message:
            "Nous n'avons pas réussi a relever ta position, pense à activer la localisation!",
          title: 'Whoups!',
        });
        setLocalLoading(false);
        return;
      }

      await confirmTakingCharge({
        variables: {
          input: {
            journeyId: journey.id,
            takingChargeGpsAddress: currentLocation,
          },
        },
      });
      setLocalLoading(false);
    } catch {
      setLocalLoading(false);
    }
  };

  const { handleClick: handleConfirmTakingChargeWithDynamicLabel, label } = useDynamicLabel(
    handleConfirmTakingCharge,
    'Bien pris en charge 👌',
    dynamicLabelOptions
  );

  const handleDeclineTakingCharge = async () => {
    try {
      if (
        !(await confirm({
          confirmLabel: 'Oui',
          message: 'Tu as raté le rendez-vous?',
          title: 'Décliner la prise en charge',
        }))
      )
        return;

      await declineTakingCharge({
        variables: {
          input: {
            journeyId: journey.id,
          },
        },
      });
    } catch {
      // Nothing to do
    }
  };

  return (
    <PageWrapper
      subtitle="Le conducteur a indiqué que le trajet avait démarré"
      title="Ton trajet démarre!"
      withoutLogo
    >
      <ListItem>
        <ListItemIcon>
          <MapOutlined />
        </ListItemIcon>
        <ListItemText
          primary="Départ - Arrivée"
          secondary={`${trip.startAddress.mainText} - ${trip.endAddress.mainText}`}
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon>
          <DirectionsCarOutlined />
        </ListItemIcon>
        <ListItemText
          primary={`${trip.car.brandName} - ${trip.car.modelName}`}
          secondary={`${trip.car.registrationNumber}`}
        />
      </ListItem>

      <Stack mt={6} spacing={3}>
        <LoadingLabeledButton
          disabled={declineTakingChargeState.loading}
          loading={localLoading}
          onClick={handleConfirmTakingChargeWithDynamicLabel}
          variant="contained"
        >
          {label}
        </LoadingLabeledButton>
        <Divider>OU</Divider>
        <LoadingButton
          disabled={confirmTakingChargeState.loading}
          loading={declineTakingChargeState.loading}
          onClick={handleDeclineTakingCharge}
          variant="contained"
        >
          Loupé 🥲
        </LoadingButton>
        <AbortTripOrJourneyConditions isConductor={false} />

        <HelpLink additionalInfos={{ tripId: trip.id }} />
      </Stack>
    </PageWrapper>
  );
}

export default WaitingForDecision;
