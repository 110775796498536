import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

const useCopyToClipboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = useCallback(
    (text) => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        })
        .catch(() => {
          enqueueSnackbar('Erreur lors de la copie. Réessayez.', { variant: 'error' });
        });
    },
    [enqueueSnackbar]
  );

  return { copied, copyToClipboard };
};

export default useCopyToClipboard;
