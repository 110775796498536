import { StarOutlineOutlined } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Avatar from 'src/components/@extended/Avatar';
import { PATH_USER } from 'src/routes/paths';

function RateTripNotificationItem({ onClose, rating }) {
  return (
    <ListItemButton
      LinkComponent={RouterLink}
      onClick={onClose}
      to={PATH_USER.trips.detail(rating.tripId)}
    >
      <ListItemIcon>
        <Avatar color="warning" size="sm">
          <StarOutlineOutlined sx={{ fontSize: 16 }} />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="inherit">
            Évalue {rating.conductor.firstname} et ton trajet, c&apos;est important pour nous
          </Typography>
        }
        secondary={`${rating.trip.startAddress.mainText} - ${rating.trip.endAddress.mainText}`}
      />
    </ListItemButton>
  );
}

export default RateTripNotificationItem;
