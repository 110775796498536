import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import useAuth from 'src/contexts/auth/useAuth';
import { FileProvider } from 'src/contexts/FileContext';
import { useLocation } from 'src/contexts/LocationContext';
import useDynamicLabel from 'src/hooks/useDynamicLabel';
import { PATH_USER } from 'src/routes/paths';

import Onboarding from '../onboarding';
import SponsorStepContent from './SponsorStepContent';
import step5ImageSrc from './step5.gif';
import Step5Content from './Step5Content';
import Step6Content from './Step6Content';

function WelcomeOnboarding() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { dynamicLabelOptions, hasPermission, requestPermissions } = useLocation();

  const handleOnboardingEnd = useCallback(async () => {
    if (hasPermission) {
      navigate(PATH_USER.home);
      return;
    }

    await requestPermissions();
    navigate(PATH_USER.home);
  }, [hasPermission, navigate, requestPermissions]);

  const { handleClick, label } = useDynamicLabel(handleOnboardingEnd, null, dynamicLabelOptions);

  const step6Children = useCallback(
    ({ setNextDisabled, setOnClick }) => (
      <Step6Content setNextDisabled={setNextDisabled} setOnClick={setOnClick} />
    ),
    []
  );

  const sponsorStepChildren = useCallback(
    ({ setNextDisabled }) => <SponsorStepContent setNextDisabled={setNextDisabled} />,
    []
  );

  const sponsorStep = useMemo(() => {
    if (window.contacts.isAvailableSync()) {
      return { children: sponsorStepChildren };
    }

    return {
      content: 'Augmente tes revenus en partageant ton code parrain à tous tes amis',
      imageSrc: step5ImageSrc,
    };
  }, [sponsorStepChildren]);

  const STEPS = useMemo(
    () => [
      {
        ...sponsorStep,
        subtitle: 'Un parrainage hors norme',
        title: 'Parrainage',
      },
      {
        children: step6Children,
        subtitle: (
          <span>
            Tu dois être seul et reconnaissable. Retire tes lunettes, casquette et tout autre
            accessoire!
            <br />
            1, 2, 3... Ouistiti!
          </span>
        ),
        title: 'Un petit selfie',
      },
      {
        content: <Step5Content />,
        onClick: handleClick,
        subtitle: 'Active la géolocalisation sur ton téléphone',
        title: 'Dernière étape',
      },
    ],
    [handleClick, sponsorStep, step6Children]
  );

  return (
    <FileProvider>
      <Onboarding
        disableClickIndicator
        endActionLabel={label}
        forceHideOnboarding={user.selfie}
        forceShowOnboarding={!user.selfie}
        fullHeight
        name="post-signup-v1"
        notSkipable
        steps={STEPS}
      />
    </FileProvider>
  );
}

export default WelcomeOnboarding;
