import 'moment/locale/fr';

import { format, formatDistanceToNow, formatDistanceToNowStrict, getTime } from 'date-fns';
import * as locales from 'date-fns/locale';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';

import MOMENT_FORMATS from '../constants/momentFormats';
import i18n from '../locales/i18n';
import useLocales from './useLocales';

const useFormatDateTime = () => {
  const { currentLang } = useLocales();
  moment.tz.setDefault('Europe/Paris');
  const COMMON_FORMAT_OPTIONS = useMemo(
    () => ({
      locale: locales[currentLang.value],
    }),
    [currentLang.value]
  );

  const fDate = useCallback(
    (date) => format(date ? new Date(date) : new Date(), 'dd MMMM', COMMON_FORMAT_OPTIONS),
    [COMMON_FORMAT_OPTIONS]
  );

  const fFullDate = useCallback(
    (date) => format(date ? new Date(date) : new Date(), 'dd MMMM yyyy', COMMON_FORMAT_OPTIONS),
    [COMMON_FORMAT_OPTIONS]
  );

  const fTime = useCallback(
    (date) => format(new Date(date), 'HH:mm', COMMON_FORMAT_OPTIONS),
    [COMMON_FORMAT_OPTIONS]
  );
  const fDateTime = useCallback(
    (date) => format(new Date(date), 'dd MMM yyyy à HH:mm', COMMON_FORMAT_OPTIONS),
    [COMMON_FORMAT_OPTIONS]
  );

  const fTimestamp = useCallback((date) => getTime(new Date(date)), []);

  const fTimestampToFormattedDate = useCallback(
    (timestamp) => fDate(new Date(timestamp * 1000)),
    [fDate]
  );

  const fTimestampToFormattedDateTime = useCallback(
    (timestamp) => fDateTime(new Date(timestamp * 1000)),
    [fDateTime]
  );

  const fDateTimeSuffix = useCallback(
    (date) => format(new Date(date), 'dd/MM/yyyy hh:mm p', COMMON_FORMAT_OPTIONS),
    [COMMON_FORMAT_OPTIONS]
  );

  const fToNow = useCallback(
    (date, addSuffix = true) =>
      formatDistanceToNow(new Date(date), {
        ...COMMON_FORMAT_OPTIONS,
        addSuffix,
      }),
    [COMMON_FORMAT_OPTIONS]
  );

  const fToNowStrict = useCallback(
    (date, addSuffix = true) =>
      formatDistanceToNowStrict(new Date(date), {
        ...COMMON_FORMAT_OPTIONS,
        addSuffix,
      }),
    [COMMON_FORMAT_OPTIONS]
  );

  const fDateToHumanReadable = useCallback(
    (_date, addSuffix) => {
      const date = moment(_date);
      if (date.isSame(moment(), 'day')) return i18n.t('commons.today');
      if (date.isSame(moment().subtract(1, 'days'), 'day')) return i18n.t('commons.yesterday');
      if (date.isSame(moment().add(1, 'days'), 'day')) return i18n.t('commons.tomorrow');

      let strictDate = null;
      if (date.isBefore(moment())) strictDate = moment(date).add(1, 'day');
      if (date.isAfter(moment())) strictDate = moment(date).subtract(1, 'day');

      return _.capitalize(fToNowStrict(strictDate, addSuffix));
    },
    [fToNowStrict]
  );

  const fRelativeDate = useCallback((date) => moment(date).locale('fr').fromNow(), []);

  const fDateToUtc = useCallback(
    (date) => moment(date).add(moment(date).utcOffset(), 'minutes').utc(),
    []
  );

  const fLocaleDate = useCallback(
    (date) => moment.tz(date, 'Europe/Paris').format(MOMENT_FORMATS.FR_DATE),
    []
  );
  const fLocaleDateTime = useCallback(
    (date) => moment.tz(date, 'Europe/Paris').format(MOMENT_FORMATS.FR_DATE_TIME),
    []
  );

  const hookResult = useMemo(
    () => ({
      fDate,
      fDateTime,
      fDateTimeSuffix,
      fDateToHumanReadable,
      fDateToUtc,
      fFullDate,
      fLocaleDate,
      fLocaleDateTime,
      fRelativeDate,
      fTime,
      fTimestamp,
      fTimestampToFormattedDate,
      fTimestampToFormattedDateTime,
      fToNow,
    }),
    [
      fDate,
      fDateTime,
      fDateTimeSuffix,
      fDateToHumanReadable,
      fDateToUtc,
      fFullDate,
      fLocaleDate,
      fLocaleDateTime,
      fRelativeDate,
      fTime,
      fTimestamp,
      fTimestampToFormattedDate,
      fTimestampToFormattedDateTime,
      fToNow,
    ]
  );

  return hookResult;
};

export default useFormatDateTime;
