import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import TutorialElement from './TutorialElement';

const TutorialContext = createContext();

function TutorialContextProvider({ children }) {
  const [tutorials, setTutorials] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTutorialName, setSelectedTutorialName] = useState(null);
  const [open, setOpen] = useState(false);

  const registerTutorial = useCallback(
    (tutorialName, tutorialSteps) =>
      setTutorials((currentTutorials) => ({ ...currentTutorials, [tutorialName]: tutorialSteps })),
    []
  );

  const handleOpenTutorial = useCallback((name) => {
    setActiveStep(0);
    setSelectedTutorialName(name);
    setOpen(true);
  }, []);

  const handleCloseTutorial = useCallback(() => {
    setOpen(false);
    setActiveStep(0);
    setSelectedTutorialName(null);

    const fakeElement = document.getElementById('tutorial:fake-element');
    fakeElement.remove();
  }, []);

  const handleNextStep = useCallback(() => {
    setActiveStep((active) => active + 1);
  }, []);

  const handlePreviousStep = useCallback(() => {
    setActiveStep((active) => active - 1);
  }, []);

  const goToStep = useCallback(
    (index) => {
      if (tutorials && selectedTutorialName) {
        if (index < tutorials[selectedTutorialName].length) setActiveStep(index);
      }
    },
    [selectedTutorialName, tutorials]
  );

  const hasNext = useMemo(
    () =>
      selectedTutorialName &&
      tutorials[selectedTutorialName] &&
      activeStep < tutorials[selectedTutorialName].length - 1,
    [activeStep, selectedTutorialName, tutorials]
  );

  const hasPrevious = useMemo(() => activeStep > 0, [activeStep]);

  const isTutorialOpen = useMemo(
    () =>
      selectedTutorialName &&
      tutorials[selectedTutorialName] &&
      tutorials[selectedTutorialName][activeStep] &&
      open,
    [activeStep, open, selectedTutorialName, tutorials]
  );

  const contextValue = useMemo(
    () => ({
      goToStep,
      handleCloseTutorial,
      handleNextStep,
      handleOpenTutorial,
      handlePreviousStep,
      hasNext,
      hasPrevious,
      open: isTutorialOpen,
      registerTutorial,
    }),
    [
      goToStep,
      handleCloseTutorial,
      handleNextStep,
      handleOpenTutorial,
      handlePreviousStep,
      hasNext,
      hasPrevious,
      isTutorialOpen,
      registerTutorial,
    ]
  );

  const tutorialStep = useMemo(
    () =>
      selectedTutorialName &&
      tutorials[selectedTutorialName] &&
      tutorials[selectedTutorialName][activeStep],
    [activeStep, selectedTutorialName, tutorials]
  );

  return (
    <TutorialContext.Provider value={contextValue}>
      {children}
      <TutorialElement step={tutorialStep} />
    </TutorialContext.Provider>
  );
}

const useTutorialContext = () => useContext(TutorialContext);

export { useTutorialContext };
export default TutorialContextProvider;
