import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useLottie } from 'lottie-react';
import React, { useCallback, useEffect, useState } from 'react';
import contactsAnimationData from 'src/assets/animations/contacts.json';
import MobileSheet from 'src/components/MobileSheet';

import ContactShareList from './ContactShareList';

function SponsorStepContent() {
  const [hasDenied, setHasDenied] = useState(false);

  const [loadingContacts, setLoadingContacts] = useState(false);
  const [open, setOpen] = useState(false);
  const [contacts, setContacts] = useState(null);

  const { View } = useLottie({
    animationData: contactsAnimationData,
    loop: true,
  });

  const getContactsPermissions = useCallback(async () => {
    try {
      await window.contacts.getPermissions();
    } catch {
      setHasDenied(true);
    }
  }, []);

  const loadContacts = useCallback(async () => {
    try {
      setLoadingContacts(true);
      const phoneContacts = await window.contacts.getContacts();
      setContacts(phoneContacts);
      setOpen(true);
    } catch {
      setHasDenied(true);
      setOpen(false);
      setContacts(null);
    }
    setLoadingContacts(false);
  }, []);

  useEffect(() => {
    getContactsPermissions();
  }, [getContactsPermissions]);

  return (
    <Stack flex={1} p={3} width="100%">
      <Stack alignItems="center" spacing={2}>
        {View}
      </Stack>
      <Stack alignItems="center" spacing={2}>
        <Typography align="center" variant="caption">
          Partage Coccinelle avec tous tes proches en un seul clique
        </Typography>
        <Button onClick={loadContacts} size="small">
          Accéder aux contacts
        </Button>
      </Stack>
      {hasDenied && (
        <Stack alignItems="center" spacing={2}>
          <Typography align="center" variant="caption">
            Autorise nous l&apos;accès à tes contacts depuis tes réglages pour partager ton lien de
            parrainage facilement!
          </Typography>
          <Button onClick={window.openSettings} size="small" variant="outlined">
            Aller aux réglages
          </Button>
        </Stack>
      )}

      <MobileSheet notSwipeable open={open} withoutPullToRefresh>
        {loadingContacts && <CircularProgress size={32} variant="indeterminate" />}
        {contacts && <ContactShareList contacts={contacts} onClose={() => setOpen(false)} />}
      </MobileSheet>
    </Stack>
  );
}

export default SponsorStepContent;
