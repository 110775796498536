import { StarOutlineOutlined } from '@mui/icons-material';
import { AvatarGroup, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Avatar from 'src/components/@extended/Avatar';
import UserAvatar from 'src/components/users/UserAvatar';
import { PATH_USER } from 'src/routes/paths';

function RatePassengerNotificationItem({ onClose, rating }) {
  return (
    <ListItemButton
      LinkComponent={RouterLink}
      onClick={onClose}
      to={PATH_USER.trips.detail(rating.tripId)}
    >
      <ListItemIcon>
        <AvatarGroup>
          {rating.trip.journeys.map(({ passenger }) => (
            <UserAvatar key={passenger.id} size="sm" user={passenger} />
          ))}
          <Avatar color="warning" size="sm">
            <StarOutlineOutlined sx={{ fontSize: 16 }} />
          </Avatar>
        </AvatarGroup>
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="inherit">
            Comment s&apos;est passé le trajet? Donne ton avis
          </Typography>
        }
        secondary={`${rating.trip.startAddress.mainText} - ${rating.trip.endAddress.mainText}`}
      />
    </ListItemButton>
  );
}

export default RatePassengerNotificationItem;
