import { CloseOutlined, NotificationsActiveOutlined } from '@mui/icons-material';
import {
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useShowBonuses } from 'src/components/WithBonuses';
import useAuth from 'src/contexts/auth/useAuth';
import { useNotification } from 'src/contexts/NotificationContext';

import NotificationItem from './notification-item';
import NOTIFICATION_TYPES from './notificationTypes';

function Grabber() {
  return (
    <Stack direction="row" justifyContent="center" sx={{ bottom: 0, position: 'sticky' }}>
      <Box sx={{ backgroundColor: 'green.contrastText', borderRadius: 32, height: 3, width: 32 }} />
    </Stack>
  );
}

function NotificationsList() {
  const showBonuses = useShowBonuses();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {
    getMyPendingJourneysAsConductor,
    getMyPendingRatings,
    getMyTripsWithUnreadConversations,
    myPendingJourneysAsConductor,
    myPendingRatingsAsConductor,
    myPendingRatingsAsPassenger,
    tripsWithUnreadConversations,
  } = useNotification();
  const { user } = useAuth();

  const handleClose = () => setDrawerOpen(false);

  const {
    hasSignedSwornStatement: hasSignedSwornStatementShort,
    needSwornStatement: needSwornStatementShort,
  } = user.initialRewardsInformations.short;

  useEffect(() => {
    getMyPendingJourneysAsConductor();
    getMyTripsWithUnreadConversations();
    getMyPendingRatings();
  }, [getMyPendingJourneysAsConductor, getMyPendingRatings, getMyTripsWithUnreadConversations]);

  const swornStatementNotifications = useMemo(() => {
    if (!showBonuses) return [];
    const notifications = [];
    if (needSwornStatementShort && !hasSignedSwornStatementShort)
      notifications.push({
        type: NOTIFICATION_TYPES.SWORN_STATEMENT,
      });

    return notifications;
  }, [showBonuses, needSwornStatementShort, hasSignedSwornStatementShort]);

  const bookJourneyNotifications = useMemo(
    () =>
      myPendingJourneysAsConductor?.map((trip) => ({
        ...trip,
        type: NOTIFICATION_TYPES.BOOK_JOURNEY,
      })),
    [myPendingJourneysAsConductor]
  );

  const tripsWithUnreadConversationsNotifications = useMemo(
    () =>
      tripsWithUnreadConversations?.map((trip) => ({
        ...trip,
        type: NOTIFICATION_TYPES.UNREAD_MESSAGE,
      })),
    [tripsWithUnreadConversations]
  );

  const myPendingRatingsAsConductorNotifications = useMemo(
    () =>
      myPendingRatingsAsConductor
        ?.reduce((acc, it) => {
          if (acc.find((rating) => rating.tripId === it.tripId)) {
            return acc;
          }

          acc.push(it);

          return acc;
        }, [])
        ?.map((rating) => ({
          ...rating,
          type: NOTIFICATION_TYPES.RATE_PASSENGER,
        })),
    [myPendingRatingsAsConductor]
  );

  const myPendingRatingsAsPassengerNotifications = useMemo(
    () =>
      myPendingRatingsAsPassenger?.map((rating) => ({
        ...rating,
        type: NOTIFICATION_TYPES.RATE_TRIP,
      })),
    [myPendingRatingsAsPassenger]
  );

  if (
    !myPendingJourneysAsConductor ||
    !tripsWithUnreadConversations ||
    !myPendingRatingsAsConductor ||
    !myPendingRatingsAsPassenger
  )
    return null;

  const notificationsList = [
    ...swornStatementNotifications,
    ...tripsWithUnreadConversationsNotifications,
    ...bookJourneyNotifications,
    ...myPendingRatingsAsConductorNotifications,
    ...myPendingRatingsAsPassengerNotifications,
  ];

  return (
    <>
      <Badge badgeContent={notificationsList.length} color="primary">
        <IconButton onClick={() => setDrawerOpen(!drawerOpen)} size="small">
          <NotificationsActiveOutlined />
        </IconButton>
      </Badge>
      <SwipeableDrawer
        anchor="top"
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        open={drawerOpen}
        sx={{ '& .MuiPaper-root': { border: 'none', pb: 1 } }}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between" p={2}>
          <Typography variant="subtitle1">Notifications</Typography>
          <IconButton onClick={() => setDrawerOpen(false)} size="small">
            <CloseOutlined />
          </IconButton>
        </Stack>
        <Divider />
        {!notificationsList.length && (
          <Stack p={2}>
            <Typography align="center">Tu es à jour 👌</Typography>
          </Stack>
        )}
        <List>
          {notificationsList.map(({ type, ...notificationItem }, index) => (
            <NotificationItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              item={notificationItem}
              onClose={handleClose}
              type={type}
            />
          ))}
        </List>
        <Grabber />
      </SwipeableDrawer>
    </>
  );
}

export default NotificationsList;
