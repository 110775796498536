import { Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import COUNTRY from './country';
import RHFSelectSearchable from './RHFSelectSearchable';

function RHFCountry({ id, ...props }) {
  const countryOptions = useMemo(
    () => COUNTRY.map(({ code, image, label }) => ({ image, label, value: code })),
    []
  );

  return (
    <RHFSelectSearchable
      disablePortal={false}
      fullWidth
      name={id}
      options={countryOptions}
      renderInputValue={(option) => `${option.image}  ${option.label}`}
      renderOption={(option) => (
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography>{option.image}</Typography>
          <Typography>{option.label}</Typography>
        </Stack>
      )}
      searchable
      {...props}
    />
  );
}

export default RHFCountry;
