import { Container, Grid, Stack, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from 'src/components/logo/Logo';
import useResponsive from 'src/hooks/useResponsive';

import PreSignupOnboarding from '../onboardings/pre-signup-onboarding/PreSignupOnboarding';

function AuthWrapper({ children, isAdmin, pageTitle, withoutLogo, ...props }) {
  const isMobile = useResponsive('down', 'md');

  return (
    <>
      <Container
        disableGutters={false}
        maxWidth={isMobile ? 'xs' : 'sm'}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          minHeight: '100vh',
          py: 4,
        }}
        {...props}
      >
        <Grid alignItems="center" container justifyContent="center" spacing={6} sx={{ px: 2 }}>
          <Grid item md={6} xs={12}>
            <Stack alignItems="center" spacing={2}>
              {!withoutLogo && <Logo isAdmin={isAdmin} size={48} />}
              <AnimatePresence mode="wait">
                <motion.div
                  key={pageTitle}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 100 }}
                  initial={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.15 }}
                >
                  <Typography variant="h3">{pageTitle}</Typography>
                </motion.div>
              </AnimatePresence>
            </Stack>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              ...(!isMobile && { borderLeft: (theme) => `1px dashed ${theme.palette.divider}` }),
            }}
            xs={12}
          >
            {children}
          </Grid>
        </Grid>
      </Container>

      <PreSignupOnboarding />
    </>
  );
}

AuthWrapper.propTypes = {
  children: PropTypes.node,
};

export default AuthWrapper;
