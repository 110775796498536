import { Box, styled } from '@mui/material';
import React from 'react';
import Snackbar from 'src/components/@extended/Snackbar';

import EnvIndicator from './components/EnvIndicator';
import ErrorBoundary from './components/error-boundary';
import NoInternetHandler from './components/NoInternetHandler';
import { PullToRefreshProvider } from './components/pull-to-refresh/PullToRefreshContext';
import ScrollTop from './components/ScrollTop';
import ThemeHandler from './components/ThemeHandler';
import Notistack from './components/third-party/Notistack';
import TutorialContextProvider from './components/tutorial/TutorialContext';
import { AlertProvider } from './contexts/AlertContext';
import { AuthProvider } from './contexts/auth/AuthContext';
import { ConnectivityProvider } from './contexts/ConnectivityContext';
import { CurrentTripProvider } from './contexts/CurrentTripContext';
import { DialogsProvider } from './contexts/DialogsContext';
import { LocationProvider } from './contexts/LocationContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { OnboardingProvider } from './contexts/OnboardingContext';
import { SEOProvider } from './contexts/SEOContext';
import { LandingInformationProvider } from './contexts/useLandingInformation';
import { UserProvider } from './contexts/UserContext';
import ExternalLinksBinder from './messages/ExternalLinksBinder';
import NativeFeatures from './messages/NativeFeatures';
import GlobalRoutes from './routes';
import ThemeCustomization from './themes';

function App() {
  return (
    <ThemeCustomization>
      <ErrorBoundary>
        <Notistack>
          <OnboardingProvider>
            <PullToRefreshProvider>
              <ConnectivityProvider>
                <NoInternetHandler />
                <NativeFeatures />
                <DialogsProvider>
                  <ScrollTop>
                    <LandingInformationProvider>
                      <AuthProvider>
                        <ThemeHandler>
                          <TutorialContextProvider>
                            <AlertProvider>
                              <UserProvider>
                                <LocationProvider>
                                  <NotificationProvider>
                                    <CurrentTripProvider>
                                      <SEOProvider>
                                        <GlobalRoutes />
                                        <Snackbar />
                                        <ExternalLinksBinder />
                                        <EnvIndicator />
                                      </SEOProvider>
                                    </CurrentTripProvider>
                                  </NotificationProvider>
                                </LocationProvider>
                              </UserProvider>
                            </AlertProvider>
                          </TutorialContextProvider>
                        </ThemeHandler>
                      </AuthProvider>
                    </LandingInformationProvider>
                  </ScrollTop>
                </DialogsProvider>
              </ConnectivityProvider>
            </PullToRefreshProvider>
          </OnboardingProvider>
        </Notistack>
      </ErrorBoundary>
    </ThemeCustomization>
  );
}

export default App;
