import React, { createContext, useContext, useMemo } from 'react';
import expectedErrors from 'src/graphql/file/expectedErrors';
import {
  ASSERT_DRIVING_LICENSE,
  CREATE_FILE,
  MARK_SWORN_STATEMENT_AS_READ,
  PREDICT_BANK_STATEMENT,
  PREDICT_DRIVING_LICENSE,
  SIGN_SWORN_STATEMENT,
  UPLOAD_AVATAR,
  UPLOAD_KYC_DOCUMENT,
  UPLOAD_SELFIE,
} from 'src/graphql/file/mutations';
import { GET_SWORN_STATEMENT } from 'src/graphql/file/queries';
import { useSafeLazyQuery, useSafeMutation } from 'src/services/apollo-client/wrappers';

const FileContext = createContext();

const useFile = () => useContext(FileContext);

function FileProvider({ children }) {
  const [predictBankStatement, { data: bankStatementPrediction, ...predictBankStatementState }] =
    useSafeMutation(
      PREDICT_BANK_STATEMENT,
      {
        displaySnackbar: false,
        refetchQueries: ['me'],
      },
      {
        silentError: true,
      }
    );

  const [predictDrivingLicense, { data: prediction, ...predictDrivingLicenseState }] =
    useSafeMutation(
      PREDICT_DRIVING_LICENSE,
      {
        displaySnackbar: false,
        refetchQueries: ['me'],
      },
      {
        silentError: true,
      }
    );

  const [assertDrivingLicense, { ...assertDrivingLicenseState }] = useSafeMutation(
    ASSERT_DRIVING_LICENSE,
    {
      displaySnackbar: false,
      refetchQueries: ['me'],
    },
    {
      expectedErrors: [expectedErrors.DRIVING_LICENSE_ALREADY_EXISTS],
      silentError: false,
    }
  );

  const [uploadKYCDocument, { ...uploadKYCDocumentState }] = useSafeMutation(
    UPLOAD_KYC_DOCUMENT,
    {
      displaySnackbar: false,
      refetchQueries: ['me'],
    },
    {
      silentError: true,
    }
  );

  const [createFile, { data: file, ...createFileState }] = useSafeMutation(
    CREATE_FILE,
    {
      displaySnackbar: false,
      refetchQueries: ['me'],
    },
    {
      silentError: true,
    }
  );

  const [uploadSelfie, uploadSelfieState] = useSafeMutation(
    UPLOAD_SELFIE,
    {
      displaySnackbar: false,
      refetchQueries: ['me'],
    },
    {
      expectedErrors: [
        expectedErrors.INVALID_SELFIE,
        expectedErrors.METADATA_CREATION_DATE_TOO_OLD,
        expectedErrors.NO_CREATION_DATE_FOUND_IN_IMAGE_METADATA,
      ],
      silentError: false,
    }
  );

  const [uploadAvatar, uploadAvatarState] = useSafeMutation(
    UPLOAD_AVATAR,
    {
      displaySnackbar: false,
      refetchQueries: ['me'],
    },
    {
      silentError: true,
    }
  );

  const [signSwornStatement, signSwornStatementState] = useSafeMutation(
    SIGN_SWORN_STATEMENT,
    {
      displaySnackbar: false,
      refetchQueries: ['me'],
    },
    {
      silentError: true,
    }
  );

  const [markSwornStatementAsRead, markSwornStatementAsReadState] = useSafeMutation(
    MARK_SWORN_STATEMENT_AS_READ,
    {
      displaySnackbar: false,
    },
    {
      silentError: true,
    }
  );

  const [getSwornStatement, { data: getSwornStatementData, ...getSwornStatementState }] =
    useSafeLazyQuery(
      GET_SWORN_STATEMENT,
      {
        displaySnackbar: false,
      },
      {
        silentError: true,
      }
    );

  const memoizedContextValue = useMemo(
    () => ({
      assertDrivingLicense,
      assertDrivingLicenseState,
      bankStatementPrediction: bankStatementPrediction?.predictBankStatement,
      createFile,
      createFileState,
      file,
      getSwornStatement,
      getSwornStatementState,
      markSwornStatementAsRead,
      markSwornStatementAsReadState,
      predictBankStatement,
      predictBankStatementState,
      predictDrivingLicense,
      predictDrivingLicenseState,
      prediction: prediction?.predictDrivingLicense,
      signSwornStatement,
      signSwornStatementState,
      swornStatementFile: getSwornStatementData?.getSwornStatement,
      uploadAvatar,
      uploadAvatarState,
      uploadKYCDocument,
      uploadKYCDocumentState,
      uploadSelfie,
      uploadSelfieState,
    }),
    [
      assertDrivingLicense,
      assertDrivingLicenseState,
      bankStatementPrediction?.predictBankStatement,
      createFile,
      createFileState,
      file,
      getSwornStatement,
      getSwornStatementData?.getSwornStatement,
      getSwornStatementState,
      markSwornStatementAsRead,
      markSwornStatementAsReadState,
      predictBankStatement,
      predictBankStatementState,
      predictDrivingLicense,
      predictDrivingLicenseState,
      prediction?.predictDrivingLicense,
      uploadAvatar,
      uploadAvatarState,
      uploadKYCDocument,
      uploadKYCDocumentState,
      uploadSelfie,
      uploadSelfieState,
      signSwornStatement,
      signSwornStatementState,
    ]
  );

  return <FileContext.Provider value={memoizedContextValue}>{children}</FileContext.Provider>;
}

export { FileProvider, useFile };
