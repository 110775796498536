import React from 'react';
import { RatingProvider } from 'src/contexts/RatingContext';

import NotificationsList from './NotificationsList';

function Notifications() {
  return (
    <RatingProvider>
      <NotificationsList />
    </RatingProvider>
  );
}

export default Notifications;
