import React, { useCallback, useEffect, useState } from 'react';

import TutorialBackdrop from './TutorialBackdrop';
// eslint-disable-next-line import/no-cycle
import { useTutorialContext } from './TutorialContext';
// eslint-disable-next-line import/no-cycle
import TutorialPopover from './TutorialPopover';

const TEMP_SPACING = 16;

function TutorialElement({ step }) {
  const { open: isTutorialOpen } = useTutorialContext();
  const [dimensions, setDimensions] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(Boolean(anchorEl));

  const handleTutorialStep = useCallback(async () => {
    setDimensions({
      height: window.innerHeight - 2 * TEMP_SPACING,
      left: TEMP_SPACING,
      top: TEMP_SPACING,
      width: window.innerWidth - 2 * TEMP_SPACING,
    });
    if (!step) return;

    if (step.clickBefore) {
      setOpen(false);
      await new Promise((resolve) => {
        setTimeout(() => {
          const beforeElement = document.getElementById(step.clickBefore);
          beforeElement?.click();

          setTimeout(() => {
            resolve();
          }, 500);
        }, 50);
      });
    }

    const { id } = step;
    setOpen(false);

    let fakeElement = document.getElementById('tutorial:fake-element');

    fakeElement = fakeElement || document.createElement('div');

    fakeElement.style.position = 'fixed';
    fakeElement.style.bottom = 0;
    fakeElement.style.left = '50%';
    fakeElement.setAttribute('id', 'tutorial:fake-element');
    document.body.appendChild(fakeElement);

    const element = document.getElementById(id) || fakeElement;

    if (!element) return;

    setAnchorEl(element);

    const { height, left, top, width } = element.getBoundingClientRect();

    setDimensions({ height, left, top, width });
  }, [step]);

  useEffect(() => {
    handleTutorialStep();
  }, [handleTutorialStep]);

  useEffect(() => {
    setOpen(false);
    setTimeout(() => setOpen(Boolean(anchorEl)), 250);

    return () => {
      setOpen(Boolean(anchorEl));
    };
  }, [anchorEl]);

  useEffect(() => {
    if (!isTutorialOpen) {
      setAnchorEl(false);
    }
  }, [isTutorialOpen]);

  if (!step) return null;

  return (
    <>
      {open && (
        <TutorialPopover
          anchorEl={anchorEl}
          clickItemBeforeContinue={step.clickItemBeforeContinue}
          content={step.content}
          open={isTutorialOpen}
          placement={step.placement}
          title={step.title}
        />
      )}
      {isTutorialOpen && Boolean(anchorEl) && <TutorialBackdrop dimensions={dimensions} />}
    </>
  );
}

export default TutorialElement;
