import { CloseOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  CardContent,
  CardHeader,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';

import safeCallback from '../../utils/safeCallback';
// eslint-disable-next-line import/no-cycle
import { useTutorialContext } from './TutorialContext';

const computePlacement = (placement) => {
  switch (placement) {
    case 'top':
      return {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
        placement: 'top',
        transformOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      };
    case 'right':
      return {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'center',
        },
        placement: 'right',
        transformOrigin: {
          horizontal: 'left',
          vertical: 'center',
        },
      };
    case 'left':
      return {
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'center',
        },
        placement: 'left',
        transformOrigin: {
          horizontal: 'right',
          vertical: 'center',
        },
      };
    case 'bottom':
    default:
      return {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        placement: 'bottom',
        transformOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      };
  }
};

const computePaperMargin = (placement) => {
  switch (placement) {
    case 'top':
      return {
        m: 0,
        mb: 2,
        transform: 'translateY(-16px) !important',
      };
    case 'right':
      return {
        m: 0,
        ml: 2,
      };
    case 'left':
      return {
        m: 0,
        mr: 2,
        transform: 'translateX(-16px) !important',
      };
    case 'bottom':
    default:
      return {
        m: 0,
        mt: 2,
      };
  }
};

function TutorialPopover({ anchorEl, clickItemBeforeContinue, content, open, placement, title }) {
  const [loading, setLoading] = useState(false);
  const { handleCloseTutorial, handleNextStep, hasNext, hasPrevious } = useTutorialContext();

  const handleNext = useCallback(() => {
    setLoading(true);
    if (clickItemBeforeContinue) {
      anchorEl.click();

      setTimeout(() => {
        handleNextStep();
        setLoading(false);
      }, 500);
      return;
    }

    safeCallback(handleNextStep);
    setLoading(false);
  }, [anchorEl, clickItemBeforeContinue, handleNextStep]);

  return (
    <Popover
      anchorEl={anchorEl}
      disablePortal
      open={open}
      {...computePlacement(placement)}
      slotProps={{
        paper: {
          sx: {
            borderColor: (theme) => theme.palette.divider,
            borderStyle: 'solid',
            borderWidth: 1,
            overflow: 'visible',
            position: 'relative',
            py: 1,
            width: 300,
            ...computePaperMargin(placement),
          },
        },
      }}
      sx={{ zIndex: 99999 }}
    >
      <CardHeader
        action={
          <Box sx={{ position: 'absolute', right: 8, top: 8 }}>
            <IconButton onClick={handleCloseTutorial} size="small">
              <CloseOutlined sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        }
        title={title}
        titleTypographyProps={{ align: 'center' }}
      />
      <CardContent>
        <Typography align="center">{content}</Typography>
      </CardContent>
      <Stack alignItems="center" direction="row" justifyContent="center" p={1} spacing={1}>
        {/* {hasPrevious && <Button onClick={handlePreviousStep}>Précédent</Button>} */}
        {hasNext && (
          <LoadingButton loading={loading} onClick={handleNext} variant="outlined">
            {hasPrevious && 'Suivant'}
            {!hasPrevious && 'Commencer'}
          </LoadingButton>
        )}
        {!hasNext && (
          <LoadingButton loading={loading} onClick={handleCloseTutorial} variant="outlined">
            Terminer
          </LoadingButton>
        )}
      </Stack>
    </Popover>
  );
}

export default TutorialPopover;
