import { Stack, Typography } from '@mui/material';
import React from 'react';
import earnMoneyAnimationData from 'src/assets/animations/earn-money.json';

import AnimationWrapper from '../AnimationWrapper';

function EarnMoneyStepContent() {
  return (
    <Stack alignItems="center" spacing={3}>
      <AnimationWrapper
        animationData={earnMoneyAnimationData}
        animationProps={{ loop: true }}
        withBackground
      />
      <Typography align="center" variant>
        Soit récompensé dès ton premier trajet! <br />
        Et en plus, tu y gagneras toujours à partager ce bon plan avec tes amis!
      </Typography>
    </Stack>
  );
}

export default EarnMoneyStepContent;
