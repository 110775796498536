import React from 'react';

import NOTIFICATION_TYPES from '../notificationTypes';
import BookJourneyNotificationItem from './BookJourneyNotificationItem';
import RatePassengerNotificationItem from './RatePassengerNotificationItem';
import RateTripNotificationItem from './RateTripNotificationItem';
import SwornStatementNotificationItem from './SwornStatementNotificationItem';
import UnreadMessageNotificationItem from './UnreadMessageNotificationItem';

function NotificationItem({ item, onClose, type }) {
  if (type === NOTIFICATION_TYPES.SWORN_STATEMENT)
    return <SwornStatementNotificationItem onClose={onClose} />;
  if (type === NOTIFICATION_TYPES.BOOK_JOURNEY)
    return <BookJourneyNotificationItem onClose={onClose} trip={item} />;
  if (type === NOTIFICATION_TYPES.UNREAD_MESSAGE)
    return <UnreadMessageNotificationItem onClose={onClose} trip={item} />;
  if (type === NOTIFICATION_TYPES.RATE_TRIP)
    return <RateTripNotificationItem onClose={onClose} rating={item} />;
  if (type === NOTIFICATION_TYPES.RATE_PASSENGER)
    return <RatePassengerNotificationItem onClose={onClose} rating={item} />;

  return null;
}

export default NotificationItem;
