import { gql } from '@apollo/client';

import { ratingFragment } from '../fragments';

const SKIP_RATING = gql`
  mutation skipRating($tripId: ID!, $userId: ID) {
    skipRating(tripId: $tripId, userId: $userId) {
      ...Rating
    }
  }
  ${ratingFragment}
`;

const RATE_TRIP = gql`
  mutation rateTrip($tripId: ID!, $input: rateTripInput!) {
    rateTrip(tripId: $tripId, input: $input) {
      ...Rating
    }
  }
  ${ratingFragment}
`;

const RATE_PASSENGER = gql`
  mutation ratePassenger($tripId: ID!, $passengerId: ID!, $input: ratePassengerInput!) {
    ratePassenger(tripId: $tripId, input: $input, passengerId: $passengerId) {
      ...Rating
    }
  }
  ${ratingFragment}
`;

export { SKIP_RATING, RATE_TRIP, RATE_PASSENGER };
