import { LinearProgress, Stack } from '@mui/material';
import React from 'react';

function OnboardingIndicator({ activeStep, count, onIndicatorClick }) {
  if (count <= 1) return null;

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="center"
      px={1}
      spacing={0.3}
      sx={{ left: 0, position: 'absolute', top: 8, width: '100%', zIndex: 99999 }}
    >
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <LinearProgress
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            sx={{ flex: 1, height: 3 }}
            value={index <= activeStep ? 100 : 0}
            variant="determinate"
            {...(!onIndicatorClick && {
              onClick: () => onIndicatorClick(index),
            })}
          />
        ))}
    </Stack>
  );
}

export default OnboardingIndicator;
