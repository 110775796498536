import { Box, Stack, alpha, styled } from '@mui/material';
import React from 'react';
import CircularLoader from 'src/components/CircularLoader';
import useResponsive from 'src/hooks/useResponsive';

const PhoneWrapper = styled(Box)(({ scale = 1, theme }) => ({
  backgroundColor: theme.palette.common.black,
  borderRadius: 24,
  boxShadow: `${theme.shadows[16]}, inset -2px -2px 2px 0px rgba(255, 255, 255, 0.4), inset 2px 2px 3px 0px rgba(255, 255, 255, 0.5)`,
  height: 400,
  minWidth: 191,
  padding: theme.spacing(0.75),
  paddingBottom: theme.spacing(0.75),
  position: 'relative',
  transform: `scale(${scale})`,
  transition: 'all 0.25s ease',
}));

const Video = styled('video')(({ theme }) => ({
  borderRadius: 20,
  boxShadow: `inset 0px 0px 10px 0px ${theme.palette.common.black}`,
  height: '100%',
  width: 'auto',
}));

const Image = styled('img')({
  borderRadius: 20,
  height: '100%',
  width: 'auto',
});

const Content = styled('div')(({ theme }) => ({
  '&::webkit-scrollbar': {
    height: 'auto',
    width: '2px',
  },
  backgroundColor: theme.palette.background.default,
  borderRadius: 20,
  height: '100%',
  minHeight: 388,
  overflow: 'hidden',
  position: 'relative',
  scrollbarWidth: '2px !important',
  width: 'auto',
}));

const LoaderLayout = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  borderRadius: 20,
  display: 'flex',
  height: `calc(100% - ${theme.spacing(1.7)})`,
  justifyContent: 'center',
  left: 0,
  margin: theme.spacing(0.75),
  minHeight: 388,
  position: 'absolute',
  top: 0,
  width: `calc(100% - ${theme.spacing(1.7)})`,
  zIndex: -1,
}));

const Notch = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  height: 8,
  left: '50%',
  position: 'absolute',
  top: 5,
  transform: 'translateX(-50%)',
  width: 58,
  zIndex: 10,
}));

const FakeVolumeButtonWrapper = styled(Stack)({
  left: -2,
  position: 'absolute',
  top: 72,
});

const FakeVolumeButton = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  borderBottomLeftRadius: 16,
  borderTopLeftRadius: 16,
  height: 25,
  width: 2,
  zIndex: 999,
}));

const FakePowerButton = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  borderBottomRightRadius: 16,
  borderTopRightRadius: 16,
  height: 45,
  position: 'absolute',
  right: -2,
  top: 72,
  width: 2,
  zIndex: 999,
}));

const FakeHomeBar = styled(Stack)(({ theme }) => ({
  backdropFilter: 'blur(16px)',
  backgroundColor: alpha(theme.palette.common.black, 0.9),
  borderRadius: 16,
  bottom: 8,
  height: 3,
  left: '50%',
  position: 'absolute',
  transform: 'translateX(-50%)',
  width: 64,
  zIndex: 999,
}));

function FakePhone({ children, imageSrc, noScale, scale, videoSrc }) {
  const isMobile = useResponsive('down', 'md');

  let finalScale = isMobile || noScale ? 1 : 1.2;
  finalScale = scale || finalScale;

  return (
    <PhoneWrapper scale={finalScale}>
      <Notch />
      <FakeVolumeButtonWrapper spacing={1}>
        <FakeVolumeButton />
        <FakeVolumeButton />
      </FakeVolumeButtonWrapper>
      <FakePowerButton />
      <FakeHomeBar />
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <LoaderLayout>
        <CircularLoader />
      </LoaderLayout>
      {videoSrc && <Video autoPlay loop src={videoSrc} />}
      {imageSrc && <Image src={imageSrc} />}
      {children && <Content>{children}</Content>}
    </PhoneWrapper>
  );
}

export default FakePhone;
