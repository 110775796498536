import { gql } from '@apollo/client';

const mangoPayKycInformationsFragment = gql`
  fragment MangoPayKycInformations on MangoPayKycInformations {
    canCashOut
    needsKycDocumentCreation
    hasKycDocumentsExpired
    hasKycDocumentsFailed
    hasKycDocumentsSucceeded
    hasKycValidationAsked
    needKycVerificationRelatedToTripsLength
  }
`;

export default mangoPayKycInformationsFragment;
