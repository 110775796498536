import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { useJourney } from 'src/contexts/JourneyContext';
import { useLocation } from 'src/contexts/LocationContext';
import useDialogs from 'src/hooks/useDialogs';
import useDynamicLabel from 'src/hooks/useDynamicLabel';

function ConfirmDepositAction({ journey }) {
  const { confirmDeposit, confirmDepositState } = useJourney();
  const { alert, confirm } = useDialogs();
  const { dynamicLabelOptions, getCurrentLocation } = useLocation();
  const [localLoading, setLocalLoading] = useState(false);

  const handleConfirmDeposit = async () => {
    try {
      setLocalLoading(true);
      if (
        !(await confirm({
          confirmLabel: 'Confirmer',
          message: "Assure toi d'être à bon port avant de confirmer ton arrivée",
          title: 'Confirmation',
        }))
      ) {
        setLocalLoading(false);
        return;
      }

      const currentLocation = await getCurrentLocation();

      if (!currentLocation) {
        await alert({
          message:
            "Nous n'avons pas réussi a relever ta position, pense à activer la localisation!",
          title: 'Whoups!',
        });
        setLocalLoading(false);
        return;
      }

      await confirmDeposit({
        variables: {
          input: {
            depositGpsAddress: currentLocation,
            journeyId: journey.id,
          },
        },
      });
      setLocalLoading(false);
    } catch {
      setLocalLoading(false);
    }
  };

  const { handleClick: handleConfirmDepositWithDynamicLabel, label } = useDynamicLabel(
    handleConfirmDeposit,
    'Bien arrivé!',
    dynamicLabelOptions
  );

  return (
    <Stack spacing={1}>
      <LoadingButton
        loading={confirmDepositState.loading || localLoading}
        onClick={handleConfirmDepositWithDynamicLabel}
        variant="contained"
      >
        {label}
      </LoadingButton>
    </Stack>
  );
}

export default ConfirmDepositAction;
