import { ArrowBackOutlined, ArrowForwardOutlined, CheckOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Collapse, IconButton, Stack } from '@mui/material';
import React from 'react';

function OnboardingActions({
  endActionIcon,
  endActionLabel,
  isFirstStep,
  isLastStep,
  loading,
  nextDisabled,
  notSkipable,
  onEnd,
  onNextStep,
  onPreviousStep,
  // onReset,
}) {
  return (
    <Stack alignItems="center" direction="row" px={3} spacing={1}>
      <>
        <Collapse appear in={isFirstStep && !notSkipable} orientation="horizontal" unmountOnExit>
          <Button disabled={loading} fullWidth onClick={onEnd} size="small">
            Passer le tutoriel
          </Button>
        </Collapse>
        <Collapse appear in={!isFirstStep && !isLastStep} orientation="horizontal" unmountOnExit>
          <IconButton
            color="primary"
            disabled={loading}
            fullWidth
            onClick={onPreviousStep}
            size="small"
            sx={{ mr: 1 }}
            variant="outlined"
          >
            <ArrowBackOutlined sx={{ fontSize: 16 }} />
          </IconButton>
        </Collapse>
        {!isLastStep ? (
          <LoadingButton
            disabled={nextDisabled}
            endIcon={<ArrowForwardOutlined />}
            fullWidth
            loading={loading}
            onClick={onNextStep}
            variant="outlined"
          >
            {isFirstStep ? 'Commencer' : 'Suivant'}
          </LoadingButton>
        ) : (
          <Button
            disabled={nextDisabled || loading}
            endIcon={!loading && (endActionIcon || <CheckOutlined />)}
            fullWidth
            onClick={onEnd}
            startIcon={loading && <CircularProgress color="inherit" size={14} />}
            variant="outlined"
          >
            {endActionLabel || 'Terminé'}
          </Button>
        )}
      </>
    </Stack>
  );
}

export default OnboardingActions;
