// assets
import { BorderOutlined, DownOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import {
  Box,
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import useResponsive from 'src/hooks/useResponsive';

import Dot from '../../../../../components/@extended/Dot';
import Transitions from '../../../../../components/@extended/Transitions';
import SimpleBar from '../../../../../components/third-party/SimpleBar';
import { MenuOrientation, ThemeMode } from '../../../../../config';
import useConfig from '../../../../../hooks/useConfig';
import { dispatch, useSelector } from '../../../../../store';
import { activeItem } from '../../../../../store/reducers/menu';
// assets
import NavItem from './NavItem';

// mini-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
  '&:before': {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.grey.A800}`,
    borderLeft: `1px solid ${theme.palette.grey.A800}`,
    content: '""',
    display: 'block',
    height: 10,
    left: -5,
    position: 'absolute',
    top: 38,
    transform: 'translateY(-50%) rotate(45deg)',
    width: 10,
    zIndex: 120,
  },
  minWidth: 180,
  overflow: 'visible',
}));

// ==============================|| NAVIGATION - LIST COLLAPSE ||============================== //

function NavCollapse({
  level,
  menu,
  parentId,
  selectedItems,
  selectedLevel,
  setSelectedItems,
  setSelectedLevel,
}) {
  const theme = useTheme();

  const isMobile = useResponsive('down', 'md');
  const menuState = useSelector((state) => state.menu);

  const { drawerOpen } = menuState;
  const { menuOrientation } = useConfig();
  const navigation = useNavigate();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(null);
    setSelectedLevel(level);
    if (drawerOpen) {
      setOpen(!open);
      setSelected(!selected ? menu.id : null);
      setSelectedItems(!selected ? menu.id : '');
      if (menu.url) navigation(`${menu.url}`);
    } else {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handlerIconLink = () => {
    if (!drawerOpen) {
      if (menu.url) navigation(`${menu.url}`);
      setSelected(menu.id);
    }
  };

  const handleHover = (event) => {
    setAnchorEl(event?.currentTarget);
    if (!drawerOpen) {
      setSelected(menu.id);
    }
  };

  const miniMenuOpened = Boolean(anchorEl);

  const handleClose = () => {
    setOpen(false);
    if (!miniMenuOpened) {
      if (!menu.url) {
        setSelected(null);
      }
    }
    setAnchorEl(null);
  };

  useMemo(() => {
    if (selected === selectedItems) {
      if (level === 1) {
        setOpen(true);
      }
    } else if (level === selectedLevel) {
      setOpen(false);
      if (!miniMenuOpened && !drawerOpen && !selected) {
        setSelected(null);
      }
      if (drawerOpen) {
        setSelected(null);
      }
    }
  }, [selectedItems, level, selected, miniMenuOpened, drawerOpen, selectedLevel]);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === menu.url) {
      setSelected(menu.id);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const checkOpenForParent = (child, id) => {
    child.forEach((item) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  useEffect(() => {
    setOpen(false);
    if (!miniMenuOpened) {
      setSelected(null);
    }
    if (miniMenuOpened) setAnchorEl(null);
    if (menu.children) {
      menu.children.forEach((item) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id);
        }
        if (pathname && pathname.includes('product-details')) {
          if (item.url && item.url.includes('product-details')) {
            setSelected(menu.id);
            setOpen(true);
          }
        }
        if (item.url === pathname) {
          setSelected(menu.id);
          setOpen(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu.children]);

  useEffect(() => {
    if (menu.url === pathname) {
      dispatch(activeItem({ openItem: [menu.id] }));
      setSelected(menu.id);
      setAnchorEl(null);
      setOpen(true);
    }
  }, [pathname, menu]);

  const navCollapse = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return (
          <NavCollapse
            key={item.id}
            level={level + 1}
            menu={item}
            parentId={parentId}
            selectedItems={selectedItems}
            selectedLevel={selectedLevel}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
          />
        );
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} align="center" color="error" variant="h6">
            Fix - Collapse or Item
          </Typography>
        );
    }
  });

  const isSelected = selected === menu.id;
  const borderIcon = level === 1 ? <BorderOutlined style={{ fontSize: '1rem' }} /> : false;
  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
  ) : (
    borderIcon
  );
  const textColor = theme.palette.mode === ThemeMode.DARK ? 'grey.400' : 'text.primary';
  const iconSelectedColor =
    theme.palette.mode === ThemeMode.DARK && drawerOpen
      ? theme.palette.text.primary
      : theme.palette.primary.main;
  const popperId = miniMenuOpened ? `collapse-pop-${menu.id}` : undefined;
  const FlexBox = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {menuOrientation === MenuOrientation.VERTICAL || isMobile ? (
        <>
          <ListItemButton
            disableRipple
            selected={selected === menu.id}
            {...(!drawerOpen && { onMouseEnter: handleClick, onMouseLeave: handleClose })}
            onClick={handleClick}
            sx={{
              pl: drawerOpen ? `${level * 12}px` : 1.5,
              py: !drawerOpen && level === 1 ? 1.25 : 1,
              ...(drawerOpen && {
                '&.Mui-selected': {
                  '&:hover': {
                    bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'transparent',
                    color: iconSelectedColor,
                  },
                  bgcolor: 'transparent',
                  color: iconSelectedColor,
                },
                '&:hover': {
                  bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
                },
              }),
              ...(!drawerOpen && {
                '&.Mui-selected': {
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                  bgcolor: 'transparent',
                },
                '&:hover': {
                  bgcolor: 'transparent',
                },
              }),
            }}
          >
            {menuIcon && (
              <ListItemIcon
                onClick={handlerIconLink}
                sx={{
                  color: selected === menu.id ? 'primary.main' : textColor,
                  minWidth: 28,
                  ...(!drawerOpen && {
                    '&:hover': {
                      bgcolor:
                        theme.palette.mode === ThemeMode.DARK
                          ? 'secondary.light'
                          : 'secondary.lighter',
                    },
                    alignItems: 'center',
                    borderRadius: 1.5,
                    height: 36,
                    justifyContent: 'center',
                    width: 36,
                  }),
                  ...(!drawerOpen &&
                    selected === menu.id && {
                      '&:hover': {
                        bgcolor:
                          theme.palette.mode === ThemeMode.DARK
                            ? 'primary.darker'
                            : 'primary.lighter',
                      },
                      bgcolor:
                        theme.palette.mode === ThemeMode.DARK ? 'primary.900' : 'primary.lighter',
                    }),
                }}
              >
                {menuIcon}
              </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
              <ListItemText
                primary={
                  <Typography color={selected === menu.id ? 'primary' : textColor} variant="h6">
                    {menu.title}
                  </Typography>
                }
                secondary={
                  menu.caption && (
                    <Typography color="secondary" variant="caption">
                      {menu.caption}
                    </Typography>
                  )
                }
              />
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) &&
              (miniMenuOpened || open ? (
                <UpOutlined
                  style={{ color: theme.palette.primary.main, fontSize: 10, marginLeft: 1 }}
                />
              ) : (
                <DownOutlined style={{ fontSize: 10, marginLeft: 1 }} />
              ))}

            {!drawerOpen && (
              <PopperStyled
                anchorEl={anchorEl}
                open={miniMenuOpened}
                placement="right-start"
                popperOptions={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [-12, 1],
                      },
                    },
                  ],
                }}
                style={{
                  zIndex: 2001,
                }}
              >
                {({ TransitionProps }) => (
                  <Transitions in={miniMenuOpened} {...TransitionProps}>
                    <Paper
                      sx={{
                        backgroundImage: 'none',
                        border: `1px solid ${theme.palette.divider}`,
                        boxShadow: theme.customShadows.z1,
                        mt: 1.5,
                        overflow: 'hidden',
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                        <SimpleBar
                          sx={{
                            maxHeight: 'calc(100vh - 170px)',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                          }}
                        >
                          {navCollapse}
                        </SimpleBar>
                      </ClickAwayListener>
                    </Paper>
                  </Transitions>
                )}
              </PopperStyled>
            )}
          </ListItemButton>
          {drawerOpen && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List sx={{ p: 0 }}>{navCollapse}</List>
            </Collapse>
          )}
        </>
      ) : (
        <ListItemButton
          aria-describedby={popperId}
          disableRipple
          id={`boundary-${popperId}`}
          onClick={handleHover}
          onMouseEnter={handleHover}
          onMouseLeave={handleClose}
          selected={isSelected}
          sx={{
            '&.Mui-selected': {
              bgcolor: 'transparent',
            },
          }}
        >
          <Box onClick={handlerIconLink} sx={FlexBox}>
            {menuIcon && (
              <ListItemIcon
                sx={{
                  color: theme.palette.secondary.dark,
                  minWidth: !menu.icon ? 18 : 36,
                  my: 'auto',
                }}
              >
                {menuIcon}
              </ListItemIcon>
            )}
            {!menuIcon && level !== 1 && (
              <ListItemIcon
                sx={{
                  '&:hover': { bgcolor: 'transparent' },
                  bgcolor: 'transparent',
                  minWidth: !menu.icon ? 18 : 36,
                  my: 'auto',
                }}
              >
                <Dot color={isSelected ? 'primary' : 'secondary'} size={4} />
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <Typography color="inherit" sx={{ my: 'auto' }} variant="body1">
                  {menu.title}
                </Typography>
              }
            />
            {miniMenuOpened ? <RightOutlined /> : <DownOutlined />}
          </Box>

          {anchorEl && (
            <PopperStyled
              anchorEl={anchorEl}
              id={popperId}
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [-10, 0],
                  },
                },
              ]}
              open={miniMenuOpened}
              placement="right-start"
              style={{
                zIndex: 2001,
              }}
            >
              {({ TransitionProps }) => (
                <Transitions in={miniMenuOpened} {...TransitionProps}>
                  <Paper
                    sx={{
                      backgroundImage: 'none',
                      boxShadow: theme.shadows[8],
                      mt: 1.5,
                      overflow: 'hidden',
                      py: 0.5,
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <SimpleBar
                        sx={{
                          maxHeight: 'calc(100vh - 170px)',
                          overflowX: 'hidden',
                          overflowY: 'auto',
                        }}
                      >
                        {navCollapse}
                      </SimpleBar>
                    </ClickAwayListener>
                  </Paper>
                </Transitions>
              )}
            </PopperStyled>
          )}
        </ListItemButton>
      )}
    </>
  );
}

NavCollapse.propTypes = {
  level: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  menu: PropTypes.object,
  parentId: PropTypes.string,
  selectedItems: PropTypes.string,
  selectedLevel: PropTypes.number,
  setSelectedItems: PropTypes.func,
  setSelectedLevel: PropTypes.func,
};

export default NavCollapse;
