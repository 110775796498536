import { CloseOutlined, SearchOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import FadedBorder from 'src/components/FadedBorder';
import PageWrapper from 'src/components/PageWrapper';
import config from 'src/config/index';
import useAuth from 'src/contexts/auth/useAuth';
import { useLandingInformation } from 'src/contexts/useLandingInformation';
import { PATH_AUTH } from 'src/routes/paths';
import safeCallback from 'src/utils/safeCallback';

const getSMSMessage = (initialReward, sponsoredReward, sponsoredUrl, sponsorCode) =>
  `Hello 👋 
La nouvelle application de covoiturage Coccinelle 🐞 peut te faire gagner ${initialReward}€ si tu partages ton prochain trajet dessus!
Inscris-toi avec mon code parrain (${sponsorCode}) pour ${sponsoredReward}€ de plus, ou passe directement par mon lien: ${sponsoredUrl}`;

function ContactShareList({ contacts, onClose }) {
  const { user } = useAuth();
  const inputRef = useRef();
  const { rewardsLandingInformations } = useLandingInformation();
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const availableContacts = useMemo(
    () =>
      contacts
        .filter((contact) => contact.phoneNumbers?.[0]?.number && contact?.name)
        .sort((a, b) => {
          const nameA = `${a.firstName} ${a.lastName}`.toUpperCase();
          const nameB = `${b.firstName} ${b.lastName}`.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
    [contacts]
  );

  const handleClickItem = useCallback(
    (contactId) => {
      if (selectedContacts.includes(contactId)) {
        setSelectedContacts((oldSelectedContacts) =>
          oldSelectedContacts.filter((id) => id !== contactId)
        );
      } else {
        setSelectedContacts((oldSelectedContacts) => [...oldSelectedContacts, contactId]);
      }
    },
    [selectedContacts]
  );

  const handleSendSMS = useCallback(() => {
    const selectedNum = availableContacts
      .filter(({ id }) => selectedContacts.includes(id))
      .map(({ phoneNumbers }) => phoneNumbers[0].number);

    window.SMS.send(
      selectedNum,
      getSMSMessage(
        rewardsLandingInformations.initialRewardForUser,
        rewardsLandingInformations.rewardForSponsored,
        `${config.deepLinkUrl}${PATH_AUTH.register}?sponsorId=${user.id}`,
        user.sponsorCode
      )
    );
    safeCallback(onClose);
  }, [
    availableContacts,
    rewardsLandingInformations.initialRewardForUser,
    rewardsLandingInformations.rewardForSponsored,
    user.id,
    user.sponsorCode,
    onClose,
    selectedContacts,
  ]);

  const selectedContactsList = useMemo(
    () => availableContacts.filter(({ id }) => selectedContacts.includes(id)),
    [availableContacts, selectedContacts]
  );

  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const handleClearSearchInput = useCallback(() => {
    setSearchValue('');
    inputRef.current.focus();
  }, []);

  const filteredContacts = useMemo(() => {
    if (!searchValue) return availableContacts;

    return availableContacts.filter(({ name }) =>
      name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [availableContacts, searchValue]);

  return (
    <PageWrapper
      goBackTo={onClose}
      subtitle="Peut être pas tout le monde, mais ça peut en intéresser certains"
      title="Contacts"
      withCloseIcon
      withoutLogo
    >
      <Stack flex={1} spacing={1}>
        <Box
          sx={{
            backgroundColor: 'background.default',
            pb: 2,
            position: 'sticky',
            pt: 4,
            px: 2,
            top: 0,
            zIndex: 2,
          }}
        >
          <Grid columnSpacing={0.5} container spacing={1}>
            <Grid item xs={12}>
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearchInput} size="small">
                      <CloseOutlined sx={{ fontSize: 16 }} />
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth
                inputRef={inputRef}
                onChange={handleSearchChange}
                placeholder="Rechercher un contact"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchOutlined sx={{ fontSize: 16 }} />
                  </InputAdornment>
                }
                value={searchValue}
              />
            </Grid>
            {Boolean(selectedContactsList.length) && (
              <>
                {selectedContactsList.slice(0, 5).map(({ id, name }) => (
                  <Grid key={name} item>
                    <Chip
                      color="primary"
                      label={name}
                      onDelete={() => handleClickItem(id)}
                      size="small"
                      variant="combined"
                    />
                  </Grid>
                ))}
                {Boolean(selectedContactsList.slice(5).length) && (
                  <Grid item>
                    <Chip
                      color="primary"
                      label={`+${selectedContactsList.slice(5).length}`}
                      size="small"
                      variant="combined"
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <FadedBorder bottom={-25} height={25} left={0} to="top" width="100%" />
        </Box>
        <List sx={{ padding: 0 }}>
          {filteredContacts.map(({ id, name, phoneNumbers }) => (
            <ListItemButton key={id} onClick={() => handleClickItem(id)}>
              <ListItemIcon>
                <Checkbox checked={selectedContacts.includes(id)} />
              </ListItemIcon>
              <ListItemText primary={name} secondary={phoneNumbers[0].number} />
            </ListItemButton>
          ))}
        </List>
        {!filteredContacts.length && (
          <Typography align="center" color="text.secondary" variant="caption">
            Aucun contact trouvé
          </Typography>
        )}
        {Boolean(filteredContacts.length) && (
          <Toolbar
            sx={{
              bottom: (theme) => theme.spacing(4),
              justifyContent: 'center',
              position: 'sticky',
            }}
          >
            <Button disabled={!selectedContacts.length} onClick={handleSendSMS} variant="contained">
              {Boolean(selectedContacts.length) &&
                `Partager avec ${selectedContacts.length} contacts`}
              {!selectedContacts.length && `Sélectionne tes contacts proches`}
            </Button>
          </Toolbar>
        )}
      </Stack>
    </PageWrapper>
  );
}

export default ContactShareList;
