import { useEffect } from 'react';
import { useLogger } from 'src/contexts/LoggerContext';

import MESSAGE_TYPES from './messages/messageTypes';
import postMessage from './postMessage';

const registerContactsFunctions = (isMobile) => {
  window.contacts = {
    isAvailableSync: () => false,
  };

  if (!isMobile) return;

  window.contacts = {
    // bind contacts helpers functions
    getContacts: () =>
      postMessage({
        type: MESSAGE_TYPES.GET_CONTACTS,
      }),
    getPermissions: () =>
      postMessage({
        type: MESSAGE_TYPES.GET_CONTACTS_PERMISSIONS,
      }),
    isAvailableSync: () => true,
    requestPermissions: () =>
      postMessage({
        type: MESSAGE_TYPES.REQUEST_CONTACTS_PERMISSIONS,
      }),
  };
};

const useWebView = () => {
  const { setGlobalContext } = useLogger();

  const useApp = Boolean(window.ReactNativeWebView);
  const service = useApp ? 'mobile' : 'web';

  setGlobalContext({ service });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const loadInWebview = urlParams.get('loadInWebView') === 'true';

    localStorage.setItem('load-in-webview', loadInWebview);
  }, []);

  // Register contacts functions
  registerContactsFunctions(useApp);

  // Add request permissions method
  if (!useApp) {
    navigator.geolocation.getPermissions = () =>
      navigator.permissions.query({ name: 'geolocation' });

    navigator.geolocation.getPosition = () =>
      new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

    navigator.geolocation.requestPermissions = () =>
      new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(() => resolve({ state: 'granted' }), reject);
      });

    window.camera = {
      checkPermissions: () => navigator.permissions.query({ name: 'camera' }),
      requestPermissions: () => navigator.mediaDevices.getUserMedia({ video: true }),
    };

    window.openSettings = undefined;

    // Define noop action
    window.asyncStorage = {
      storeToken: () => null,
    };

    // Open URL
    window.openURL = (url) => {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    window.registerForPushNotifications = () => null;

    return;
  }

  window.SMS = {
    send: (addresses, message) =>
      postMessage({
        args: { addresses, message },
        type: MESSAGE_TYPES.SEND_SMS,
      }),
  };

  window.asyncStorage = {
    storeToken: (token) =>
      postMessage({
        args: { token },
        type: MESSAGE_TYPES.STORE_TOKEN,
      }),
  };

  // Debug
  // eslint-disable-next-line no-console
  console.log = (args) =>
    postMessage({
      args,
      type: MESSAGE_TYPES.DEBUG,
    });

  // Override share feature
  navigator.share = (args) =>
    postMessage({
      args,
      type: MESSAGE_TYPES.SHARE,
    });

  // Override copy to clipboard feature
  if (!('clipboard' in navigator)) {
    navigator.clipboard = {};
  }

  navigator.clipboard.writeText = (args) =>
    postMessage({
      args,
      type: MESSAGE_TYPES.COPY,
    });

  navigator.geolocation.requestPermissions = () =>
    postMessage({
      type: MESSAGE_TYPES.REQUEST_POSITION_PERMISSIONS,
    });

  // Override asking permissions feature
  navigator.geolocation.getPermissions = () =>
    postMessage({
      type: MESSAGE_TYPES.GET_PERMISSIONS,
    });

  // Override getPosition feature
  navigator.geolocation.getPosition = () =>
    postMessage({
      type: MESSAGE_TYPES.GET_CURRENT_POSITION,
    });

  navigator.geolocation.watchPosition = (callback) => {
    setInterval(async () => {
      const position = await postMessage({
        type: MESSAGE_TYPES.WATCH_POSITION,
      });
      callback(position);
    }, 10_000);
  };

  // Open settings feature
  window.openSettings = () =>
    postMessage({
      type: MESSAGE_TYPES.OPEN_SETTINGS,
    });

  // Open URL
  window.openURL = (url) =>
    postMessage({
      args: {
        url,
      },
      type: MESSAGE_TYPES.OPEN_URL,
    });

  // Get deviceId feature
  window.getDeviceId = () =>
    postMessage({
      type: MESSAGE_TYPES.GET_DEVICE_ID,
    });

  // Feature to interact with native camera
  window.camera = {
    checkPermissions: async () =>
      postMessage({
        type: MESSAGE_TYPES.CHECK_CAMERA_PERMISSIONS,
      }),
    hide: () =>
      postMessage({
        type: MESSAGE_TYPES.HIDE_CAMERA,
      }),
    requestPermissions: () =>
      postMessage({
        type: MESSAGE_TYPES.REQUEST_CAMERA_PERMISSIONS,
      }),
    show: ({ left, size, top }) =>
      postMessage({
        args: { left, size, top },
        type: MESSAGE_TYPES.SHOW_CAMERA,
      }),
    takePhoto: () =>
      postMessage({
        type: MESSAGE_TYPES.TAKE_PHOTO,
      }),
  };

  window.registerForPushNotifications = () =>
    postMessage({
      type: MESSAGE_TYPES.REGISTER_FOR_PUSH_NOTIFICATIONS,
    });
};

export default useWebView;
