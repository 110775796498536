import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTutorialContext } from 'src/components/tutorial/TutorialContext';
import { useOnboarding } from 'src/contexts/OnboardingContext';
import useLocalStorage from 'src/hooks/useLocalStorage';

import WELCOME_TUTORIAL_STEPS from './welcome-tutorial-steps';

const TUTORIAL_NAME = 'welcome-tutorial';

function WelcomeTutorial() {
  const [params] = useSearchParams();
  const searchOpen = useMemo(() => params.get('search') === 'true', [params]);
  const { onboardings } = useOnboarding();

  const [haveBeenSeen, setHaveBeenSeen] = useLocalStorage(`tutorial-welcome-seen`, false);

  const { handleOpenTutorial, registerTutorial } = useTutorialContext();

  const handleOpen = useCallback(() => {
    handleOpenTutorial(TUTORIAL_NAME);
    setHaveBeenSeen(true);
  }, [handleOpenTutorial, setHaveBeenSeen]);

  useEffect(() => {
    registerTutorial(TUTORIAL_NAME, WELCOME_TUTORIAL_STEPS);
    if (searchOpen || haveBeenSeen || onboardings['post-signup-v1']) return;

    setTimeout(() => {
      handleOpen();
    }, 500);
  }, [handleOpen, haveBeenSeen, searchOpen, registerTutorial, onboardings]);

  return null;
}

export { TUTORIAL_NAME as WELCOME_TUTORIAL_NAME };
export default WelcomeTutorial;
