import { DirectionsWalkOutlined, PlaceOutlined, SportsScoreOutlined } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import useFormatDateTime from 'src/hooks/useFormatDateTime';

function TripTimeline({ trip, withoutHoursInformation }) {
  const { fTime } = useFormatDateTime();

  const endDateTime = useMemo(
    () => trip && moment(trip.startDateTime).add(trip.estimatedDurationInMinutes, 'minutes'),
    [trip]
  );

  const contentGridSize = withoutHoursInformation ? 11 : 9;

  return (
    <Grid columnSpacing={1} container>
      {!withoutHoursInformation && (
        <Grid item xs={2}>
          <Typography variant="subtitle1">{fTime(trip.startDateTime)}</Typography>
        </Grid>
      )}
      <Grid item xs={1}>
        <Stack justifyContent="space-between">
          <Box sx={{ mt: 0.25 }}>
            <PlaceOutlined color="primary" sx={{ fontSize: 18 }} />
          </Box>
          <Box
            sx={{
              borderLeftColor: `primary.main`,
              borderLeftStyle: `dashed`,
              borderLeftWidth: `1px`,
              height: 35,
              ml: 1,
              width: 0,
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={contentGridSize}>
        <Stack>
          <Typography noWrap>
            {trip.startAddress.mainText}

            {_.isInteger(trip.departureDurationInMinutes) && (
              <Typography
                component="span"
                noWrap
                sx={{ color: 'text.secondary', fontStyle: 'italic' }}
              >
                {' · '}
                <DirectionsWalkOutlined sx={{ fontSize: 16 }} />
                <Typography noWrap variant>
                  {trip.departureDurationInMinutes > 0 ? trip.departureDurationInMinutes : `< 1`}
                  min.
                </Typography>
              </Typography>
            )}
          </Typography>
          <Typography color="text.secondary" noWrap variant="caption">
            {trip.startAddress.secondaryText}
          </Typography>
        </Stack>
      </Grid>
      {!withoutHoursInformation && (
        <Grid item xs={2}>
          <Typography variant="subtitle1">{fTime(endDateTime)}</Typography>
        </Grid>
      )}
      <Grid item xs={1}>
        <Stack justifyContent="space-between">
          <Box sx={{ mt: 0.25 }}>
            <SportsScoreOutlined color="primary" sx={{ fontSize: 18 }} />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={contentGridSize}>
        <Stack>
          <Typography noWrap>
            {trip.endAddress.mainText}

            {_.isInteger(trip.arrivalDurationInMinutes) && (
              <Typography
                component="span"
                noWrap
                sx={{ color: 'text.secondary', fontStyle: 'italic' }}
              >
                {' · '}
                <DirectionsWalkOutlined sx={{ fontSize: 16 }} />
                <Typography variant>
                  {trip.arrivalDurationInMinutes > 0 ? trip.arrivalDurationInMinutes : `< 1`}
                  min.
                </Typography>
              </Typography>
            )}
          </Typography>
          <Typography color="text.secondary" noWrap variant="caption">
            {trip.endAddress.secondaryText}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default TripTimeline;
